import {
  shipmentDestinationTypes,
  shipmentOptions
} from './book-shipment-contants';
import { shippingRates } from './shipping-rates';

export const services = Object.freeze({
  Saver: 'Saver',
  Budget: 'Budget',
  ExpressImport: 'ExpressImport',
  ExpressExport: 'ExpressExport'
});

export const itemCategoryEnum = Object.freeze({
  // Appliance: 'Appliance',
  BeautyProducts: 'Beauty products',
  // Jewellery: 'Jewelry',
  ComputerSupplies: 'Computer supplies',
  HomeDecor: 'Home decor & accessories',
  BabySupplies: 'Baby supplies',
  // TelevisionAndEntertainment: 'Television & entertainment',
  KitchenAccessories: 'Kitchen accessories',
  Furniture: 'Furniture',
  // Fashion: 'Fashion',
  Education: 'Education',
  // Gadgets: 'Gadgets',
  SolarPanelsAndInverter: 'Solar panels & inverter',
  VehicleParts: 'Vehicle parts',
  ClothingAndTextile: 'Clothing & textile',
  SportAccessories: 'Sport accessories',
  GymEquipment: 'Gym equipment',
  Drones: 'Drones',
  Document: 'Document',
  OriginalArtwork: 'Original Artwork',
  ArtPrints: 'Art Prints',
  // FoodItems: 'Food Items',
  Medication: 'Medication',
  // Fish: 'Fish',
  // Herbs: 'Herbs',
  'BatteryLiquidElectrical&PowderItems': 'BatteryLiquidElectrical&PowderItems',
  Crayfish: 'Crayfish (Blended and Dried)',
  Driedfish: 'Dried Fish',
  Prawns: 'Prawns',
  Otherfish: 'Other Fish',
  GoatMeat: 'Goat Meat',
  CowSkin: 'Cow Skin ("Ponmo")',
  Beef: 'Beef',
  Snail: 'Snail',
  OtherMeats: 'Other Meats',
  PaintingsAndDrawings: 'Paintings, Drawings',
  ArtifactsAndHistoricalMonuments: 'Artifacts and historical monuments',
  LaptopsAndTablets: 'Laptops, Tablets',
  Phones: 'Phones',
  HeadphonesOrEarphonesOrAirPods: 'Headphones, Earphones, AirPods',
  Wristwatches: 'Wristwatches',
  VideoGames: 'Video Games',
  OtherElectronicsOrGadgets: 'Other Electronics or Gadgets',
  GoldSilverAndFineJewelry: 'Gold, Silver, and Fine Jewelry',
  PreciousStonesAndJewels: 'Precious Stones and Jewels',
  CostumeJewelry: 'Costume Jewelry',
  HerbsAndPlants: 'Herbs & Plants',
  FoodstuffAndFoodProducts: 'Foodstuff & Food products',
  Drinks: 'Drinks (alcoholic and non-alcoholic beverages)',
  Others: 'Others'
});

export const itemCategoryKey = Object.freeze({
  // Appliance: 'Appliance',
  'Beauty products': 'BeautyProducts',
  Jewelry: 'Jewelry',
  'Computer supplies': 'ComputerSupplies',
  'Home decor & accessories': 'HomeDecorationAndAccessories',
  'Baby supplies': 'BabySupplies',
  'Television & entertainment': 'TelevisionAndentertainment',
  'Kitchen accessories': 'KitchenAccessories',
  Furniture: 'Furniture',
  // Fashion: 'Fashion',
  Education: 'Education',
  // Gadgets: 'Gadgets',
  'Solar panels & inverter': 'SolarPanelsAndInverter',
  'Vehicle parts': 'VehicleParts',
  'Clothing & textile': 'ClothingAndTextile',
  'Sport accessories': 'SportAccessories',
  'Gym equipment': 'GymEquipment',
  Drones: 'Drones',
  Document: 'Document',
  'Original Artwork': 'OriginalArtwork',
  'Art Prints': 'ArtPrints',
  'Food Items': 'FoodItems',
  Medication: 'Medication',
  // Fish: 'Fish',
  // Herbs: 'Herbs',
  'Battery, liquid, electrical, and powder items':
    'BatteryLiquidElectrical&PowderItems',
  'Crayfish (Blended and Dried)': 'Crayfish',
  'Dried Fish': 'Driedfish',
  Prawns: 'Prawns',
  'Other Fish': 'Otherfish',
  'Goat Meat': 'GoatMeat',
  'Cow Skin ("Ponmo")': 'CowSkin',
  Beef: 'Beef',
  Snail: 'Snail',
  'Other Meats': 'OtherMeats',
  'Paintings, Drawings': 'PaintingsAndDrawings',
  'Artifacts and historical monuments': 'ArtifactsAndHistoricalMonuments',
  'Laptops, Tablets': 'LaptopsAndTablets',
  Phones: 'Phones',
  'Headphones, Earphones, AirPods': 'HeadphonesOrEarphonesOrAirPods',
  Wristwatches: 'Wristwatches',
  'Video Games': 'VideoGames',
  'Other Electronics or Gadgets': 'OtherElectronicsOrGadgets',
  'Gold, Silver, and Fine Jewelry': 'GoldSilverAndFineJewelry',
  'Precious Stones and Jewels': 'PreciousStonesAndJewels',
  'Costume Jewelry': 'CostumeJewelry',
  'Herbs & Plants': 'HerbsAndPlants',
  'Foodstuff & Food products': 'FoodstuffAndFoodProducts',
  'Drinks (alcoholic and non-alcoholic beverages)': 'Drinks',
  Others: 'Others'
});

export const serviceNames = {
  [services.ExpressExport]: 'Express Exports',
  [services.ExpressImport]: 'Express Imports',
  [services.Saver]: 'Saver',
  [services.Budget]: 'Budget'
};

export const itemCategoryRules = {
  [itemCategoryKey['Dried Fish']]: {
    canShip: true,
    services: {
      [services.ExpressExport]: {
        canShip: true,
        notice:
          'If you are shipping with Express, maximum of 10kg can be shipped per waybill'
      },
      [services.ExpressImport]: {
        canShip: true
      },
      [services.Saver]: {
        canShip: true
      },
      [services.Budget]: {
        canShip: true
      }
    }
  },
  [itemCategoryKey['Goat Meat']]: {
    canShip: true,
    services: {
      [services.ExpressExport]: {
        canShip: false
      },
      [services.ExpressExport]: {
        canShip: false
      },
      [services.ExpressExport]: {
        canShip: false
      },
      [services.Budget]: {
        canShip: true,
        notice: 'Maximum of 2kg can be shipped per waybill'
      }
    }
  },
  [itemCategoryKey['Cow Skin ("Ponmo")']]: {
    canShip: false
  },
  [itemCategoryKey['Beef']]: {
    canShip: false
  },
  [itemCategoryKey['Snail']]: {
    canShip: true,
    services: {
      [services.ExpressExport]: {
        canShip: true,
        notice: (
          <span>
            A maximum of 2kg can be shipped per waybill. Also, a snail
            classification form is required for customs clearance.{' '}
            <a
              href={'https://topship.africa/shipping-documentation'}
              target='_blank'
              rel='noreferrer'
            >
              Click here to learn how you can get yours.
            </a>
          </span>
        )
      },
      [services.ExpressImport]: {
        canShip: false
      },
      [services.Saver]: {
        canShip: false
      },
      [services.Budget]: {
        canShip: true,
        notice: (
          <span>
            A maximum of 2kg can be shipped per waybill. Also, a snail
            classification form is required for customs clearance.{' '}
            <a
              href={'https://topship.africa/shipping-documentation'}
              target='_blank'
              rel='noreferrer'
            >
              Click here to learn how you can get yours.
            </a>
          </span>
        )
      }
    }
  },
  [itemCategoryKey['Art Prints']]: {
    canShip: true,
    notice: (
      <span>
        If you're shipping with Budget, a museum certificate is required for
        customs clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    ),
    services: {
      [services.ExpressExport]: {
        canShip: true
      },
      [services.ExpressImport]: {
        canShip: true
      },
      [services.Saver]: {
        canShip: true
      },
      [services.Budget]: {
        canShip: true,
        notice: (
          <span>
            A museum certificate is required for customs clearance.{' '}
            <a
              href={'https://topship.africa/shipping-documentation'}
              target='_blank'
              rel='noreferrer'
            >
              Click here to learn how you can get yours.
            </a>
          </span>
        )
      }
    }
  },
  [itemCategoryKey['Original Artwork']]: {
    canShip: true,
    notice: (
      <span>
        A museum certificate is required for customs clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    ),
    services: {
      [services.ExpressExport]: {
        canShip: true
      },
      [services.ExpressImport]: {
        canShip: true
      },
      [services.Saver]: {
        canShip: true
      },
      [services.Budget]: {
        canShip: true
      }
    }
  },
  [itemCategoryKey['Paintings, Drawings']]: {
    canShip: true,
    notice: (
      <span>
        A museum certificate is required for customs clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    )
  },
  [itemCategoryKey['Laptops, Tablets']]: {
    canShip: true,
    services: {
      [services.ExpressExport]: {
        canShip: true,
        notice:
          'If shipping with Express, note that a maximum of two laptops can be shipped per waybill.'
      },
      [services.ExpressImport]: {
        canShip: true,
        notice:
          'If shipping with Express, note that a maximum of two laptops can be shipped per waybill.'
      },
      [services.Saver]: {
        canShip: true
      },
      [services.Budget]: {
        canShip: true
      }
    }
  },
  [itemCategoryKey['Drones']]: {
    canShip: false
  },
  [itemCategoryKey['Gold, Silver, and Fine Jewelry']]: {
    canShip: false,
    notice: (
      <span>
        This item can only be imported with our Shop & Ship service. Go to{' '}
        <a
          target='_blank'
          href={`${window.location.origin}/shop-and-ship-welcome`}
          rel='noreferrer'
        >
          Shop & Ship
        </a>
        .
      </span>
    ),
    noticeRoute: shipmentOptions[1].value
  },
  [itemCategoryKey['Precious Stones and Jewels']]: {
    canShip: false,
    notice: (
      <span>
        This item can only be imported with our Shop & Ship service. Go to{' '}
        <a
          target='_blank'
          href={`${window.location.origin}/shop-and-ship-welcome`}
          rel='noreferrer'
        >
          Shop & Ship
        </a>
        .
      </span>
    ),
    noticeRoute: shipmentOptions[1].value
  },
  [itemCategoryKey['Medication']]: {
    canShip: true,
    notice: (
      <span>
        All pharmaceutical drugs require a doctor's prescription for customs
        clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    )
  },
  [itemCategoryKey['Herbs & Plants']]: {
    canShip: true,
    notice: (
      <span>
        All herbal products require a phytosanitary certificate for customs
        clearance.{' '}
        <a
          target='_blank'
          href={'https://topship.africa/shipping-documentation'}
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    ),
    noticeRoute: shipmentOptions[0].value
  },
  // [itemCategoryKey['Herbs']]: {
  //   canShip: true,
  //   notice: (
  //     <span>
  //       All herbal products require a phytosanitary certificate for customs
  //       clearance.{' '}
  //       <a
  //         target='_blank'
  //         href={'https://topship.africa/shipping-documentation'}
  //         rel='noreferrer'
  //       >
  //         Click here to learn how you can get yours.
  //       </a>
  //     </span>
  //   ),
  //   noticeRoute: shipmentOptions[0].value
  // },
  [itemCategoryKey['Foodstuff & Food products']]: {
    canShip: true,
    notice: (
      <span>
        If shipping to the USA with Saver, an FDA form will be required for
        customs clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    ),
    countries: ['United States'],
    services: {
      [services.ExpressExport]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      },
      [services.Saver]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      }
    }
  },
  [itemCategoryKey['Food Items']]: {
    canShip: true,
    notice: (
      <span>
        If shipping to the USA with Saver, an FDA form will be required for
        customs clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    ),
    countries: ['United States'],
    services: {
      [services.ExpressExport]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      },
      [services.Saver]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      }
    }
  },
  [itemCategoryKey['Drinks (alcoholic and non-alcoholic beverages)']]: {
    canShip: true,
    notice: (
      <span>
        If shipping to the USA with Saver, an FDA form will be required for
        customs clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    ),
    countries: ['United States'],
    services: {
      [services.ExpressExport]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      },
      [services.Saver]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      }
    }
  },
  [itemCategoryKey['Clothing & textile']]: {
    canShip: true,
    notice: (
      <span>
        If shipping to the USA and Australia with Saver, an Addendum form will
        be required for customs clearance.{' '}
        <a
          href={'https://topship.africa/shipping-documentation'}
          target='_blank'
          rel='noreferrer'
        >
          Click here to learn how you can get yours.
        </a>
      </span>
    ),
    countries: ['United States', 'Australia'],
    services: {
      [services.ExpressExport]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      },
      [services.Saver]: {
        canShip: true
      },
      [services.ExpressExport]: {
        canShip: true
      }
    }
  }
};

export const getItemCategoryMessage = (category, shipmentDetail) => {
  const settings = itemCategoryRules[category];

  if (
    !settings?.canShip &&
    (settings?.noticeRoute
      ? settings?.noticeRoute !== shipmentDetail?.shipmentRoute
      : true)
  ) {
    return <aside>This item cannot be shipped out of Nigeria</aside>;
  }

  if (settings?.notice) {
    if (
      settings?.noticeRoute &&
      shipmentDetail?.shipmentRoute !== settings?.noticeRoute
    ) {
      return null;
    }

    return <aside>{settings?.notice}</aside>;
  }

  const servicesArray = Object.entries(settings?.services).filter(
    ([service]) => {
      if (shipmentDetail?.shipmentRoute === shipmentOptions[1].value) {
        return service !== services.ExpressExport;
      }

      return service !== services.ExpressImport;
    }
  );

  const allowedServices = servicesArray.filter(
    ([, serviceSettings]) => serviceSettings?.canShip
  ).length;

  const notices = servicesArray.reduce(
    (result, service, index) => {
      if (allowedServices < servicesArray.length) {
        if (index === 0) {
          result.serviceNotice =
            'This item can ONLY be shipped out of Nigeria with the ';
        }

        if (service[1]?.canShip) {
          result.serviceNotice += `${serviceNames[service[0]]} service${
            index < allowedServices - 1 ? ' or ' : '.'
          }`;
        }
      }

      if (
        service[1]?.notice &&
        !result.notices.includes(service[1]?.notice) &&
        !result.notices.some(notice => {
          if (!notice?.props) {
            return false;
          }

          if (!service[1]?.notice?.props) {
            return false;
          }

          return (
            notice?.props?.children?.[0] ===
            service[1]?.notice?.props?.children?.[0]
          );
        })
      ) {
        result.notices.push(service[1]?.notice);
      }

      return result;
    },
    {
      serviceNotice: '',
      notices: []
    }
  );

  if (notices.serviceNotice || notices.notices.length) {
    return (
      <>
        {notices.serviceNotice && <aside>{notices.serviceNotice}</aside>}
        {notices.notices.map((notice, index) => (
          <aside key={index}>{notice}</aside>
        ))}
      </>
    );
  }

  return null;
};

export const getItemCategoryNotices = (rate, shipmentDetail, receiverIndex) => {
  if (!shipmentDetail?.items || !shipmentDetail?.items?.length) {
    return [];
  }

  if (
    shipmentDetail?.destinationType === shipmentDestinationTypes[1].value &&
    receiverIndex === undefined
  ) {
    return [];
  }

  let items = shipmentDetail?.items || [];

  if (
    shipmentDetail?.destinationType === shipmentDestinationTypes[1].value &&
    typeof receiverIndex === 'number' &&
    receiverIndex <= shipmentDetail?.multipleReceivers?.length - 1
  ) {
    items = shipmentDetail?.multipleReceivers?.[receiverIndex]?.items || [];
  }

  let service = '';

  switch (rate?.pricingTier) {
    // Express
    case shippingRates[0].value: {
      if (shipmentDetail?.shipmentRoute === shipmentOptions[1].value) {
        service = services.ExpressImport;
      } else {
        service = services.ExpressExport;
      }
      break;
    }
    // FedEx or Saver
    case shippingRates[7].value: {
      service = services.Saver;

      break;
    }
    // LastMileBudget
    case shippingRates[3].value: {
      service = services.Budget;

      break;
    }

    default:
      break;
  }

  if (!service) {
    return [];
  }

  return items
    ?.map(item => {
      const settings = itemCategoryRules[item.category];

      if (!settings) {
        return null;
      }

      if (
        !settings?.services ||
        !settings?.services?.[service] ||
        !settings?.services?.[service]?.notice
      ) {
        return null;
      }

      return <aside>{settings?.services?.[service]?.notice}</aside>;
    })
    .filter(notice => Boolean(notice));
};
