import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import BusyOverlay from '../../../../../components/busy-overlay';
import BookingSuccessfulPrompt from '../../../../../components/modals/booking-successful-prompt';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  multipleShopnshipResultsState,
  shopNShipDetailsState,
  shopNShipResultState,
  userState
} from '../../../../../recoil/atoms';
import { shipmentHubs } from '../../../../../constants/book-shipment-contants';
import { Button, Skeleton } from '@mui/material';
import {
  AccountBalanceWallet,
  CreditCard,
  CurrencyExchange,
  Store
} from '@mui/icons-material';
import SubmitButton from '../../../../../components/submit-button';
import { useHistory } from 'react-router-dom';
import Client from '../../../../../services/client';
import {
  useGenerateTransactionReferenceQuery,
  useGetVirtualBankAccount,
  useGetWalletBalanceQuery
} from '../../../../../operations/queries';
import { useSendAlert } from '../../../../../utilities/send-alert';
import { useGetUserPayload } from '../../../../../utilities/get-payload';
import {
  usePayFromWalletMutation,
  useUpdateShipmentMutation,
  useUpdateShopnshipMutation
} from '../../../../../operations/mutations';
import { transactionTypesEnum } from '../../../../../utilities/enums/transaction-types.enum';
import { usePaystackPayment } from 'react-paystack';
import { toCurrency } from '../../../../../utilities/to-currency';
import mainPaymentMethods from '../../../../../constants/payment-method';
import NewFundWalletModal from '../../../../../components/modals/new-fund-wallet-modal';
import { useLocation } from 'react-router-dom';
import { currencies } from '../../../../../constants/currencies';
import SelectCurrencyPrompt from '../../../../../components/modals/select-currency-prompt';
import {
  DeliveryAddressComponent,
  PickupAddressComponent
} from '../../../../../components/Commons/summary-component';
import insurancePlans from '../../../../../constants/insurance-plans';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import { toKoboFixed } from '../../../../../utilities/to-fixed';
import { calculateVAT } from '../../../../../utilities/calculate-vat';
import { formatUUID, redirectUrlFunc } from '../../../helper-functions';
import { productTypeEnum } from '../../../../../utilities/enums/product-type-enum';

const paymentMethods = [
  ...mainPaymentMethods,
  {
    name: 'Pay With Foreign Currency',
    cost: 'Pay with card via Stripe',
    value: 'ForeignExchange'
  }
];

const PaymentModal = ({
  show,
  warningMessage,
  handleCancel,
  handleDelete,
  deleteText
}) => {
  return (
    <div className={`delete-overlay ${show && 'delete-overlay--show'}`}>
      <div
        className={`delete-overlay__content ${
          show && 'delete-overlay__content--show'
        }`}
      >
        <figure>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z' />
          </svg>
        </figure>
        <h2>Are you sure?</h2>
        <p style={{ fontWeight: 'bolder' }}>
          {warningMessage || 'You will not be able to recover this data'}
        </p>

        <div className='delete-overlay__buttons'>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleDelete}>{deleteText || 'Delete'}</button>
        </div>
      </div>
    </div>
  );
};

const MultipleShopNShipPayment = () => {
  const history = useHistory();
  const client = Client();
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [shopNShipResult, setShopNShipResult] = useRecoilState(
    shopNShipResultState
  );
  const onCompleted = data =>
    setWalletBalance(data?.getWalletBalance?.totalBalance);
  const { loading: walletBalanceLoading, refetch } = useGetWalletBalanceQuery(
    onCompleted
  );
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);

  const [
    getVirtualBankAccount,
    getVirtualBankAccountResult
  ] = useGetVirtualBankAccount(data => {
    if (data?.generateVirtualBankAccount?.checkoutUrl) {
      window.location.replace(data?.generateVirtualBankAccount?.checkoutUrl);
    }
  });

  const location = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const sendAlert = useSendAlert();
  const userPayload = useGetUserPayload();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [successID, setSuccessID] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [expandData] = useState(true);
  const [shopNShipDetails] = useRecoilState(shopNShipDetailsState);
  const [user] = useRecoilState(userState);

  const [multipleShipmentResults, setMultipleShipmentResults] = useRecoilState(
    multipleShopnshipResultsState
  );
  const shippingFrom = shopNShipDetails?.shippingFrom?.split(' ')[0];
  const shipmentDetail = useMemo(
    () => ({
      id: shopNShipResult?.id,
      estimatedDeliveryDate: shopNShipDetails?.updatedDate,
      updatedDate: shopNShipDetails?.updatedDate,
      pricingTier: shopNShipDetails.pricingTier
        ? shopNShipDetails.pricingTier
        : 'Standard',
      multipleReceivers: [
        {
          index: 0,
          senderDetail: {
            name: user?.fullName,
            email: user?.email,
            addressLine1: shopNShipDetails?.shippingFrom,
            phoneNumber: user?.phoneNumber,
            state: shippingFrom === 'US' ? 'New York' : 'London',
            countryCode: shippingFrom === 'UK' ? 'GB' : shippingFrom
          },
          receiverDetail: {
            name: 'Topship Africa',
            email: 'hello@topship.africa',
            phoneNumber: '09080777728',
            ...shipmentHubs[1].value
          }
        },
        {
          index: 1,
          senderDetail: {
            name: 'Topship Africa',
            email: 'hello@topship.africa',
            phoneNumber: '09080777728',
            ...shipmentHubs[1].value
          },
          receiverDetail: {
            name: shopNShipDetails.deliveryName,
            email: shopNShipDetails.email,
            addressLine1: shopNShipDetails.deliveryAddress,
            phoneNumber: shopNShipDetails.phoneNumber,
            state: shopNShipDetails.deliveryState,
            countryCode: 'NG'
          }
        }
      ],
      initialCharge: shopNShipDetails?.initialCharge,
      trackingId: shopNShipDetails?.trackingId,
      shipmentStatus: shopNShipDetails?.status,
      shipmentCharge: shopNShipDetails?.initialCharge,
      totalCharge: shopNShipResult.totalCharge,
      insuranceCharge:
        (shopNShipDetails?.insuranceCharge || insurancePlans[0].amount) * 100,
      insuranceType: shopNShipDetails?.insuranceType || insurancePlans[0].value,
      lastMilePricingTier: shopNShipDetails?.lastMilePricingTier,
      lastMileCharge: shopNShipDetails?.lastMileCharge,
      lastMileShipmentId: shopNShipResult?.lastMileShipmentId,
      lastMileVAT: shopNShipDetails?.lastMileCharge
        ? calculateVAT(shopNShipDetails?.lastMileCharge)
        : 0,
      lastMileTotalCharge: shopNShipDetails?.lastMileCharge
        ? shopNShipDetails?.lastMileCharge +
          calculateVAT(shopNShipDetails?.lastMileCharge)
        : 0
    }),
    [shopNShipDetails, shopNShipResult, user]
  );

  const [openFundWalletModal, setOpenFundWalletModal] = useState(false);

  const [
    openBookingSuccessfulPrompt,
    setOpenBookingSuccessfulPrompt
  ] = useState(false);

  const [
    generateReference,
    { loading: generateReferenceLoading, data }
  ] = useGenerateTransactionReferenceQuery();
  const [isChinaImport] = useRecoilState(chinaImportState);

  const [
    payFromWalletById,
    {
      loading: walletPaymentLoading,
      data: walletPaymentData,
      error: walletPaymentError
    }
  ] = usePayFromWalletMutation();

  const handlePaymentMethodSelection = type => {
    setPaymentMethod(type);

    if (type?.value === paymentMethods[1].value) {
      generateReference(paymentID, transactionTypesEnum.cardPayment);
    }

    setOpenPaymentModal(true);
  };

  const getMethodIcon = useCallback((index = 0) => {
    switch (index) {
      // case 0: {
      //   return <AccountBalanceWallet />;
      // }

      case 1: {
        return <CreditCard />;
      }

      case 2: {
        return <Store />;
      }

      case 3: {
        return <CurrencyExchange />;
      }

      // default: {
      //   return <AccountBalance />;
      // }
      default: {
        return <AccountBalanceWallet />;
      }
    }
  }, []);

  const onSuccess = reference => {
    const shipmentCharge = isShopnship
      ? shipmentDetail?.totalCharge
      : shipmentDetail?.lastMileCharge;

    sendAlert('Payment successful!');

    client.resetStore();

    if (
      shipmentCharge &&
      walletBalance > 0 &&
      walletBalance - shipmentCharge > 0 &&
      paymentMethod?.value === paymentMethods[0].value
    ) {
      setWalletBalance(walletBalance - shipmentCharge);
    }

    if (paymentMethod?.value === paymentMethods[1].value) {
      if (isShopnship) {
        updateShopnship({
          id: paymentID.split('/')[0],
          update: { status: 'PaymentPending' }
        });
      } else {
        updateShipment(paymentID, { shipmentStatus: 'PaymentPending' });
      }
    }

    setMultipleShipmentResults(
      multipleShipmentResults?.map(result => {
        if (result?.id === paymentID.split('/')[0]) {
          return {
            ...result,
            isPaid: true
          };
        }

        return result;
      })
    );

    setOpenPaymentModal(false);

    setTimeout(() => {
      setPaymentMethod(null);
      setLoading(false);
    }, 2000);
  };

  const finishShipment = () => {
    setOpenBookingSuccessfulPrompt(true);
  };

  const onClose = () => {
    setOpenPaymentModal(false);
    setPaymentMethod(null);
    setLoading(false);
  };

  const initialReceiverSteps = useMemo(() => {
    return Array.from(
      { length: shipmentDetail.multipleReceivers.length },
      (_, index) => ({
        index,
        isActive: index === 0
      })
    );
  }, [shipmentDetail]);

  const [receiverSteps, setReceiverSteps] = useState(initialReceiverSteps);

  const currentReceiver = useMemo(() => {
    return receiverSteps.find(step => step.isActive);
  }, [receiverSteps]);

  const activeReceiverIndex = useMemo(() => {
    return receiverSteps.findIndex(step => step === currentReceiver);
  }, [currentReceiver, receiverSteps]);

  const isShopnship = useMemo(() => {
    return activeReceiverIndex === 0;
  }, [activeReceiverIndex]);

  const paymentID = useMemo(() => {
    if (isShopnship) {
      return `${shipmentDetail.id}/Initial`;
    }

    return shipmentDetail.lastMileShipmentId;
  }, [shipmentDetail, isShopnship]);

  const amount = useMemo(() => {
    return multipleShipmentResults[isShopnship ? 0 : 1]?.totalCharge / 100;
  }, [shipmentDetail, isShopnship]);

  const config = useMemo(
    () => ({
      email: userPayload?.username,
      amount: toKoboFixed(amount),
      publicKey: process.env.REACT_APP_PAYSTACK_PUB_KEY,
      reference: data && data.generateTransactionReference.id
    }),
    [amount, data, userPayload]
  );

  const initializePayment = usePaystackPayment(config);

  const [updateShipment, updateShipmentResult] = useUpdateShipmentMutation();

  const [updateShopnship, updateShopnshipResult] = useUpdateShopnshipMutation();

  const handlePayment = () => {
    // if (paymentMethod?.value === paymentMethods[4]?.value) {
    if (paymentMethod?.value === paymentMethods[3]?.value) {
      setOpenPaymentModal(false);

      setOpenCurrencyPrompt(true);
    } else if (paymentMethod.value === paymentMethods[2]?.value) {
      getVirtualBankAccount({
        id: paymentID,
        redirectUrl: `${
          window.location.origin
        }/shop-and-ship-multiple-payment?${
          isShopnship ? 'shopnship' : 'shipment'
        }SuccessID=${paymentID}`
      });
    } else if (paymentMethod.value === paymentMethods[1]?.value) {
      setLoading(true);

      initializePayment(onSuccess, onClose);
    } else {
      payFromWalletById(paymentID);
    }
  };

  const disableButton = useMemo(() => {
    if (multipleShipmentResults) {
      return multipleShipmentResults?.some(result => !result?.isPaid);
    }

    return true;
  }, [multipleShipmentResults]);

  const removeQueryId = (id = '', url = new URL(window.location.href)) => {
    if (!id) {
      return;
    }

    url.searchParams.delete(id);

    query.delete(id);
  };

  const removeSuccessID = () => {
    let url = new URL(window.location.href);

    removeQueryId('shopnshipSuccessID', url);

    removeQueryId('shipmentSuccessID', url);

    url.searchParams.delete('paymentReference');

    query.delete('paymentReference');

    window.history.replaceState(null, '', url.href.replace('?', ''));
  };

  const pageLoading =
    walletPaymentLoading ||
    generateReferenceLoading ||
    loading ||
    updateShipmentResult.loading ||
    updateShopnshipResult.loading ||
    getVirtualBankAccountResult.loading;

  useEffect(() => {
    if (walletPaymentData && !walletPaymentError) {
      onSuccess('');
    }
    // eslint-disable-next-line
  }, [walletPaymentData]);

  useEffect(() => {
    const queryShipmentID = query.get('shipmentSuccessID');

    const queryShopnshipID = query.get('shopnshipSuccessID');

    if (query && (queryShipmentID || queryShopnshipID)) {
      const resultIndex = multipleShipmentResults?.findIndex(
        (_, index) =>
          index === (queryShopnshipID ? 0 : queryShipmentID ? 1 : -1)
      );

      if (
        resultIndex !== -1 &&
        !multipleShipmentResults?.[resultIndex]?.isPaid
      ) {
        setSuccessID(resultIndex === 0 ? queryShopnshipID : queryShipmentID);
      } else {
        removeSuccessID();
      }
    }
  }, [query, location.search]);

  useEffect(() => {
    refetch()?.then(res => {
      setWalletBalance(res.data?.getWalletBalance?.totalBalance);
    });
  }, [openFundWalletModal, refetch]);

  useEffect(() => {
    if (successID) {
      const successId = formatUUID(successID.split('/')[0]);

      const isShopnship = successID.split('/').length > 1;

      if (isShopnship) {
        updateShopnship({
          id: successId,
          update: { status: 'PaymentPending' }
        });
      } else {
        updateShipment(successId, { shipmentStatus: 'PaymentPending' });
      }

      setMultipleShipmentResults(
        multipleShipmentResults?.map(result => {
          if (result?.id === successId) {
            return { ...result, isPaid: true };
          }

          return result;
        })
      );

      setSuccessID('');

      removeSuccessID();
    }
  }, [successID]);

  const activeReceiver = useMemo(() => {
    return {
      ...shipmentDetail.multipleReceivers[activeReceiverIndex],
      isPaid: multipleShipmentResults[activeReceiverIndex]?.isPaid,
      isPaymentPending:
        multipleShipmentResults[activeReceiverIndex]?.isPaymentPending
    };
  }, [activeReceiverIndex, shipmentDetail, multipleShipmentResults]);
  return (
    <NewShopNShipStepsLayout>
      <Helmet>
        <meta name='description' content='Topship Africa Shipment Payment' />
        <title>Topship Africa Shopnship Payment</title>
        <link rel='canonical' href='https://topship.africa/shipment-payment' />
      </Helmet>
      <BusyOverlay loading={pageLoading} />
      <PaymentModal
        show={openPaymentModal}
        handleCancel={() => {
          setPaymentMethod(null);
          setOpenPaymentModal(false);
        }}
        handleDelete={handlePayment}
        deleteText='Pay'
        warningMessage={`You will be paying ${toCurrency(amount)}`}
      />

      <SelectCurrencyPrompt
        openPrompt={openCurrencyPrompt}
        hideCurrencies={[currencies[0].currency]}
        setOpenPrompt={setOpenCurrencyPrompt}
        onPromptClose={() => {
          setPaymentMethod(null);
        }}
        onOpenPaymentPrompt={({ currency }) => {
          setOpenCurrencyPrompt(false);

          history.push(
            `/shipment-request-foreign-exchange-payment?${
              activeReceiverIndex === 0
                ? 'multipleShopnship'
                : 'lastMileShopnshipShipment'
            }ID=${paymentID}&currency=${currency}`
          );
        }}
      />

      <BookingSuccessfulPrompt
        isChinaImport={isChinaImport}
        handleRoute={() => history.push('/shop-and-ship-delivery-address')}
        openPrompt={openBookingSuccessfulPrompt}
        setOpenPrompt={setOpenBookingSuccessfulPrompt}
      />
      <NewFundWalletModal
        openModal={openFundWalletModal}
        setOpenModal={setOpenFundWalletModal}
        // onModalSuccess={() => history.push(multipleShipmentLinks[4].link)}
      />
      <section className='order-summary'>
        <div className='order-summary__wrap'>
          <div className='new-wallet__balance ml-0'>
            <div className='new-wallet__balance__wrap'>
              <div className='new-wallet__balance__content'>
                <div className='new-wallet__balance__content__header'>
                  <h4>Your Balance</h4>
                </div>
                <div className='new-wallet__balance__content__value'>
                  <h2>
                    {walletBalanceLoading ? (
                      <Skeleton />
                    ) : (
                      toCurrency(walletBalance / 100)
                    )}
                  </h2>
                </div>
              </div>
              <div className='new-wallet__balance__action'>
                <button onClick={() => setOpenFundWalletModal(true)}>
                  Fund Wallet
                </button>
              </div>
            </div>
          </div>
          <div className='multiple-receiver-info__item__selection'>
            <div className='multiple-receiver-info__item__selection__wrap'>
              {shipmentDetail.multipleReceivers.map((_, index) => {
                return (
                  <div
                    onClick={() =>
                      setReceiverSteps(
                        receiverSteps.map((step, i) => ({
                          ...step,
                          isActive: i === index
                        }))
                      )
                    }
                    className={`multiple-receiver-info__item__selection__option${
                      activeReceiverIndex === index ? ' active' : ''
                    }`}
                    key={index}
                  >
                    <p
                      style={{
                        fontWeight: 500
                      }}
                      className='mb-0'
                    >
                      Receiver {index + 1}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='order-summary__shipment'>
            <div className='order-summary__shipment__wrap'>
              <div className='order-summary__shipment__addresses'>
                <div className={`order-summary__shipment__address multiple`}>
                  <PickupAddressComponent
                    shipmentDetail={
                      shipmentDetail?.multipleReceivers?.[activeReceiverIndex]
                        ?.senderDetail
                    }
                    isChinaImport={isChinaImport}
                    pickupMessage='Drop off will be available once payment has been
                              received'
                  />
                </div>

                <div className='order-summary__shipment__address deliver-to'>
                  <div className='order-summary__shipment__address__wrap'>
                    <DeliveryAddressComponent receiver={activeReceiver} />
                    {(activeReceiverIndex !== 1 || expandData) &&
                      (activeReceiver?.isPaymentPending ? (
                        <div className='delivery-options__item__price'>
                          <span
                            style={{
                              color: '#efa516',
                              backgroundColor: '#df24031a',
                              fontSize: '15px'
                            }}
                          >
                            Payment Pending
                          </span>
                        </div>
                      ) : activeReceiver?.isPaid ? (
                        <div className='delivery-options__item__price'>
                          <span>Paid</span>
                        </div>
                      ) : (
                        <div
                          className='d-flex flex-column align-items-center'
                          style={{ gap: '1rem' }}
                        >
                          {paymentMethods.map((method, index) => (
                            <div
                              key={method.name}
                              className='order-summary__shipment__address__action'
                            >
                              <Button
                                onClick={() =>
                                  handlePaymentMethodSelection(
                                    method,
                                    paymentID
                                  )
                                }
                                variant='text'
                              >
                                <span>{method.name}</span>
                                {getMethodIcon(index)}
                              </Button>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='order-summary__footer'>
            <SubmitButton
              onClick={finishShipment}
              disabled={disableButton}
              text={'Finish'}
            />
          </div>
        </div>
      </section>
    </NewShopNShipStepsLayout>
  );
};

export default MultipleShopNShipPayment;
