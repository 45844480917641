import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import { SelectArrow } from '../../../../../constants/asset-contants';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  shopNShipDetailsState,
  shopnshipSelectedCountryState,
  shopnshipSelectedProductState
} from '../../../../../recoil/atoms';
import { Alert } from '@material-ui/lab';
import { Grid } from '@mui/material';
import Input from '../../../../../components/new-dashboard/custom-input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GetShopnshipHubAddress } from '../../../../../constants/shopnship-hub-address';

const SelectOptionsComponent = ({
  placeholder,
  selectedValue,
  setSelectedValue,
  optionsArray,
  label
}) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const handleValueChange = country => {
    setSelectedValue(country);
    setToggleDropdown(false);
  };

  const toggleOptions = () => {
    setToggleDropdown(prevState => !prevState);
  };

  return (
    <div>
      <label htmlFor='destination'>{label}</label>
      <div className='select-wrap'>
        <div className='selected-option' onClick={toggleOptions}>
          <p
            style={{
              color: '#545859'
            }}
          >
            {selectedValue?.label || `Select ${placeholder}`}
          </p>
          <img src={SelectArrow} alt='select arrow' />
        </div>
        {toggleDropdown ? (
          <div className='options'>
            {optionsArray.map((option, index) => {
              return (
                <div
                  key={index}
                  className='option'
                  onClick={() => handleValueChange(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const shippingFromOptions = [
  {
    value: 'UK',
    label: 'United Kingdom'
  },
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'CA',
    label: 'Canada'
  }
];
const productOptions = [
  {
    label: 'Not an electric device or gadget'
  },
  {
    label: 'An electric device or gadget'
  }
];

const ShopnshipWelcome = () => {
  const history = useHistory();
  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const currentIndex = filteredLinks.findIndex(
    link => link.link === history.location.pathname
  );
  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );

  const places = useMemo(() => {
    return {
      UK: `UK Hub: ${GetShopnshipHubAddress(
        'UK',
        shopNshipDetails?.pricingTier
      )}`,
      US: `USA Hub: ${GetShopnshipHubAddress(
        'US',
        shopNshipDetails?.pricingTier
      )}`,
      CANADA: `CANADA Hub: ${GetShopnshipHubAddress(
        'CA',
        shopNshipDetails?.pricingTier
      )}`,
      CHINA: 'CHINA Hub:'
    };
  }, [shopNshipDetails?.pricingTier]);

  const [isChinaImport] = useRecoilState(chinaImportState);
  const [selectedCountry, setSelectedCountry] = useRecoilState(
    shopnshipSelectedCountryState
  );
  const [selectedProduct, setSelectedProduct] = useRecoilState(
    shopnshipSelectedProductState
  );

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('please enter an email address'),
    phoneNumber: yup.string().required('Please enter a phone number'),
    shippingFrom: yup.string().required('Where are you shipping from?')
  });
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      email: shopNshipDetails.email || '',
      phoneNumber: shopNshipDetails.phoneNumber || '',
      shippingFrom: ''
      // pricingTier: ''
    },

    onSubmit: values => {
      setSaveShopNshipDetails({ ...shopNshipDetails, ...values });
      history.push(filteredLinks[currentIndex + 1].link);
    }
  });

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProduct(selectedProduct);
    }
    if (shopNshipDetails) {
      setSelectedCountry(selectedCountry);
    } else {
      setSelectedCountry(shippingFromOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedProduct, shopNshipDetails]);

  useEffect(() => {
    if (selectedCountry?.value === 'US') {
      setFieldValue('shippingFrom', places.US);
    } else if (selectedCountry?.value === 'CA') {
      setFieldValue('shippingFrom', places.CANADA);
    } else if (selectedCountry?.value === 'UK') {
      setFieldValue('shippingFrom', places.UK);
    } else {
      setFieldValue('shippingFrom', places.CHINA);
    }

    // eslint-disable-next-line
  }, [selectedCountry?.value, shopNshipDetails]);

  return (
    <NewShopNShipStepsLayout>
      <div className='new-shop-n-ship__modal__body'>
        <div className='new-shop-n-ship__modal__body__wrap'>
          <div className='new-shop-n-ship__modal__body__content'>
            {isChinaImport ? (
              <div className='new-shop-n-ship__modal__body__content__welcome-guide'>
                <h1 className='new-shop-n-ship__modal__body__content__welcome-guide__title mb-2'>
                  Import from <br /> China
                </h1>
                <p className='new-shop-n-ship__modal__body__content__welcome-guide__description'>
                  Receive goods from your suppliers and manufacturers in China.
                </p>

                <form
                  className='mb-5 mt-5 new-shop-n-ship__modal__body__content__welcome-guide__shipping-guide'
                  onSubmit={event => {
                    event.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Grid container rowSpacing='16px' columnSpacing='10px'>
                    <Input
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      value={values.email}
                      onChange={handleChange}
                      customLabel='Email address'
                      name='email'
                      id='email'
                      type='email'
                      containerClassName='new-shop-n-ship__modal__body__form__item__input'
                      placeholder='garrick@topship.com'
                      lg={12}
                      md={12}
                    />
                    <Input
                      id='phoneNumber'
                      name='phoneNumber'
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      onChange={value => setFieldValue('phoneNumber', value)}
                      value={values.phoneNumber}
                      type='tel'
                      placeholder='+234 801 234 5678'
                      containerClassName='new-shop-n-ship__modal__body__form__item__input'
                      customLabel='Phone Number'
                      lg={12}
                      md={12}
                    />
                  </Grid>
                  <div className='faq__questions'>
                    <div className='faq__questions__wrap'></div>
                  </div>
                  <div className='new-shop-n-ship__modal__body__content__welcome-guide__buttons'>
                    {isChinaImport ? null : (
                      <Link to='/faq'>
                        <p className='mb-2'>See how it all works</p>
                      </Link>
                    )}

                    <SubmitButton text='Continue' />
                  </div>
                  <div
                    style={{
                      paddingBottom: '20px'
                    }}
                  />
                </form>
              </div>
            ) : (
              <div className='new-shop-n-ship__modal__body__content__welcome-guide'>
                <h1 className='new-shop-n-ship__modal__body__content__welcome-guide__title mb-2'>
                  Welcome to <br /> Shop & Ship
                </h1>
                <p className='new-shop-n-ship__modal__body__content__welcome-guide__description '>
                  Shop wherever, Ship whenever!
                </p>

                <form
                  className=' mb-5 new-shop-n-ship__modal__body__content__welcome-guide__shipping-guide'
                  onSubmit={event => {
                    event.preventDefault();
                    setSaveShopNshipDetails({
                      ...shopNshipDetails,
                      shippingFrom: selectedCountry.value
                    });
                    history.push(filteredLinks[currentIndex + 1].link);
                  }}
                >
                  <div className='address-container'>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        overflow: 'hidden'
                      }}
                      className='mb-5'
                    >
                      <img
                        style={{
                          width: '90%',
                          height: 'auto',
                          margin: '0 auto'
                        }}
                        src='https://topship-web.s3.eu-west-2.amazonaws.com/adbanner3.webp'
                        alt=''
                      />
                    </div>
                    <div>
                      <SelectOptionsComponent
                        setFieldValue={setFieldValue}
                        label={'Where are you shipping from?'}
                        placeholder={'a country'}
                        selectedValue={selectedCountry}
                        setSelectedValue={setSelectedCountry}
                        optionsArray={shippingFromOptions}
                      />
                      {/* <small
                        style={{
                          paddingTop: '0'
                        }}
                      >
                        The sender's name should bear '[first name] [last name]
                        [identifier tag]
                        <strong>
                          {' '}
                          e.g Tayo Aluko -{' '}
                          {selectedCountry?.value === 'UK'
                            ? 'UAC-trstm.'
                            : selectedCountry?.value === 'CA'
                            ? 'UAC-rkipz'
                            : 'UAC-mujeq.'}
                        </strong>{' '}
                        Please add the identifier tag{' '}
                        <strong>
                          {selectedCountry?.value === 'UK'
                            ? 'UAC-trstm.'
                            : selectedCountry?.value === 'CA'
                            ? 'UAC-rkipz'
                            : 'UAC-mujeq.'}
                        </strong>{' '}
                        to your name.
                      </small> */}
                    </div>
                    <div className='mt-4'>
                      <SelectOptionsComponent
                        label={'What type of product are you purchasing?'}
                        placeholder={'product'}
                        selectedValue={selectedProduct}
                        setSelectedValue={setSelectedProduct}
                        optionsArray={productOptions}
                      />
                    </div>

                    <div className='mt-3'>
                      {selectedCountry?.label === 'China' ? (
                        <Alert
                          severity={'info'}
                          sx={{
                            fontSize: '1.5rem',
                            fontFamily: 'General Sans',
                            marginTop: '10px',
                            backgroundColor: '#e6f2fe',
                            color: '#000000',
                            border: 'none',
                            '& .MuiAlert-icon': {
                              color: '#22428F'
                            }
                          }}
                        >
                          Note that the address for drop off would be made
                          available after payment has been made
                        </Alert>
                      ) : null}
                    </div>
                  </div>
                  <div className='faq__questions'>
                    <div className='faq__questions__wrap'></div>
                  </div>
                  <div className='new-shop-n-ship__modal__body__content__welcome-guide__buttons'>
                    <Link to='/faq'>
                      <p className='mb-2'>See how it all works</p>
                    </Link>
                    <SubmitButton text='Continue' />
                  </div>
                  <div
                    style={{
                      paddingBottom: '20px'
                    }}
                  />
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </NewShopNShipStepsLayout>
  );
};

export default ShopnshipWelcome;
