import { Button, Radio } from '@mui/material';
import { toCurrency } from '../../utilities/to-currency';
import { Edit } from '@mui/icons-material';
import { AddBoxIcon } from '../../constants/asset-contants';
import { toFixed } from '../../utilities/to-fixed';

export const DeliveryAddressComponent = ({ receiver }) => {
  return (
    <div className='order-summary__shipment__address__content deliver-to'>
      <h4 className='order-summary__shipment__address__label'>DELIVER TO</h4>
      <span className='order-summary__shipment__address__name'>
        {receiver?.receiverDetail?.name || '-'}
      </span>

      <span className='order-summary__shipment__address__location'>
        {`${receiver?.receiverDetail?.addressLine1 || '-'}, ${
          receiver?.receiverDetail?.state || '-'
        }, ${receiver?.receiverDetail?.countryCode || '-'}.`}
      </span>
      <span className='order-summary__shipment__address__meta'>
        {`${receiver?.receiverDetail?.email || '-'} • ${
          receiver?.receiverDetail?.phoneNumber || '-'
        }`}
      </span>
    </div>
  );
};

export const PickupAddressComponent = ({
  shipmentDetail,
  pushRoute,
  hasCta,
  isChinaImport,
  pickupMessage
}) => {
  return (
    <div className='order-summary__shipment__address__wrap'>
      <div className='order-summary__shipment__address__content'>
        <h4 className='order-summary__shipment__address__label'>PICKUP FROM</h4>
        <span className='order-summary__shipment__address__name'>
          {shipmentDetail?.name || '-'}
        </span>

        <span className='order-summary__shipment__address__location'>
          {isChinaImport
            ? pickupMessage
            : `${shipmentDetail?.addressLine1 || '-'}, ${
                shipmentDetail?.state || '-'
              }, ${shipmentDetail?.countryCode || '-'}.`}
        </span>
        <span className='order-summary__shipment__address__meta'>
          {`${shipmentDetail?.email || '-'} • ${
            shipmentDetail?.phoneNumber || '-'
          }`}
        </span>
      </div>
      {hasCta ? (
        <div className='order-summary__shipment__address__action'>
          <Button onClick={() => pushRoute(0)} variant='text'>
            <span>edit</span>
            <Edit />
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export const PaymentBreakdownComponent = ({
  shippingFeeInKobo,
  VAT,
  totalCost
}) => {
  return (
    <div className='order-summary__coupon'>
      <div className='order-summary__coupon__wrap'>
        <div
          className='mt-1 mb-1'
          style={{
            display: 'flex',
            alignItems: 'flex-center',
            justifyContent: 'space-between'
          }}
        >
          <h4 className='mb-2'>Total Value:</h4>
          <h2
            style={{
              fontWeight: 500,
              fontSize: '18px'
            }}
          >
            {toCurrency(shippingFeeInKobo / 100)}
          </h2>
        </div>
        <div
          className='mt-1 mb-1'
          style={{
            display: 'flex',
            alignItems: 'flex-center',
            justifyContent: 'space-between'
          }}
        >
          <h4 className='mb-2'>VAT (7.5%):</h4>
          <h2
            style={{
              fontWeight: 500,
              fontSize: '18px'
            }}
          >
            {toCurrency(VAT / 100)}
          </h2>
        </div>
        <hr />
        <div
          className='mt-1 mb-1'
          style={{
            display: 'flex',
            alignItems: 'flex-center',
            justifyContent: 'space-between'
          }}
        >
          <h4 className='mb-2'>Payment Due:</h4>
          <h2
            style={{
              fontWeight: 700
            }}
          >
            {toCurrency(totalCost / 100)}
          </h2>
        </div>
      </div>
    </div>
  );
};

export const ReceiverStepsComponent = ({
  receivers,
  activeReceiverIndex,
  receiverSteps,
  setReceiverSteps
}) => {
  return (
    <div className='multiple-receiver-info__item__selection'>
      <div className='multiple-receiver-info__item__selection__wrap'>
        {receivers?.map((_, index) => {
          return (
            <div
              onClick={() => {
                setReceiverSteps(
                  receiverSteps?.map((step, i) => ({
                    ...step,
                    isActive: i === index
                  }))
                );
              }}
              className={`multiple-receiver-info__item__selection__option${
                activeReceiverIndex === index ? ' active' : ''
              }`}
              key={index}
            >
              <span
                style={{
                  fontWeight: 600
                }}
                className='mb-0'
              >
                Receiver {index + 1}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ItemInfoComponent = ({ label, value, cost, pushRoute }) => {
  return (
    <div className='order-summary__shipment__meta'>
      <div className='order-summary__shipment__meta__wrap'>
        <div className='order-summary__shipment__meta__content adjusted-width'>
          <span className='order-summary__shipment__meta__content__label'>
            {label}
          </span>
          <span className='order-summary__shipment__meta__content__value'>
            {value}
          </span>
        </div>
        {cost && (
          <div className='order-summary__shipment__meta__content adjusted-width'>
            <span className='order-summary__shipment__meta__content__label'>
              COST
            </span>
            <span className='order-summary__shipment__meta__content__value'>
              {cost}
            </span>
          </div>
        )}
        <div className='order-summary__shipment__meta__action'>
          <Button onClick={pushRoute} variant='text'>
            <span>change</span>
            <Edit />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const AddNewBoxComponent = ({
  addNewBox,
  style,
  wrapStyle,
  isSeaFreight = false
}) => {
  return (
    <div style={wrapStyle} className='add-box-style'>
      <div
        onClick={addNewBox}
        style={style}
        className='item-description__new-box'
      >
        <img src={AddBoxIcon} alt='add box' />
        <p
          style={{
            fontSize: isSeaFreight ? '12px' : '14px'
          }}
        >
          Add another {isSeaFreight ? 'package' : 'box'}
        </p>
      </div>
    </div>
  );
};

export const BudgetDeliveryOptionComponent = ({
  rate,
  selectedRate,
  selectedBudgetDelivery,
  optionText,
  priceTag,
  handleBudgetDelivery,
  checkCost,
  isFree = false
}) => {
  return (
    <div
      onClick={handleBudgetDelivery}
      className={`budget-option delivery-method ${
        selectedRate.mode?.toLowerCase() === rate.mode?.toLowerCase() &&
        checkCost
          ? ' active'
          : ''
      }`}
    >
      <div style={{ display: 'flex' }}>
        <Radio
          checked={
            selectedRate.mode?.toLowerCase() === rate.mode?.toLowerCase() &&
            checkCost
          }
          sx={{ transform: 'scale(1.5)', padding: 0 }}
        />
        <p
          style={{ paddingLeft: '10px' }}
          className={`${
            selectedRate.mode?.toLowerCase() === rate.mode?.toLowerCase() &&
            checkCost
              ? ' active'
              : ''
          }`}
        >
          {optionText}
        </p>
      </div>
      <div className=''>
        {isFree ? (
          <span className='price-tag'>FREE</span>
        ) : (
          <span className='price-tag'>
            {toCurrency(toFixed(rate?.budgetDeliveryCost / 100))}
          </span>
        )}
      </div>
    </div>
  );
};
