import { Edit, RemoveCircle } from '@mui/icons-material';
import { Button, InputAdornment, Skeleton } from '@mui/material';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import BusyOverlay from '../../../../../components/busy-overlay';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import PaymentMethodPrompt from '../../../../../components/modals/payment-method-prompt';
import Input from '../../../../../components/new-dashboard/custom-input';
import SubmitButton from '../../../../../components/submit-button';
import {
  itemCategories,
  itemCategoriesChinaImport
} from '../../../../../constants/item-categories';
import {
  useShopnShipMutation,
  useUpdateShopnshipMutation
} from '../../../../../operations/mutations';
import { useGetCouponQuery } from '../../../../../operations/queries';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  multipleShopnshipResultsState,
  shopNShipDetailsState,
  shopnshipRateState,
  shopNShipResultState,
  shopNShipValidationState,
  userState
} from '../../../../../recoil/atoms';
import { applyCouponToPrice } from '../../../../../utilities/apply-coupon-to-price';
import { calculateTotalCost } from '../../../../../utilities/calculate-total-cost';
import getPlan from '../../../../../utilities/getPlans';
import testJSON from '../../../../../utilities/test-json';
import { toCurrency } from '../../../../../utilities/to-currency';
import * as yup from 'yup';
import { toFixed } from '../../../../../utilities/to-fixed';
import omit from 'lodash.omit';
import SelectCurrencyPrompt from '../../../../../components/modals/select-currency-prompt';
import { currencies } from '../../../../../constants/currencies';
import BookingSuccessfulPrompt from '../../../../../components/modals/booking-successful-prompt';
import { WhiteCheck } from '../../../../../constants/asset-contants';
import { useSendAlert } from '../../../../../utilities/send-alert';
import { ItemInfoComponent } from '../../../../../components/Commons/summary-component';
import { calculateVAT } from '../../../../../utilities/calculate-vat';
import { pathWithoutQuery } from '../../../../../utilities/path-without-query';

const useQuery = () => new URLSearchParams(useLocation().search);

const ShopnShipSummary = () => {
  const history = useHistory();
  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);

  const currentIndex = filteredLinks.findIndex(
    link => link.link === pathWithoutQuery(history.location.pathname)
  );
  const [agreement, setAgreement] = useState(false);
  const [paymentID, setPaymentID] = useState('');
  const [openPaymentMethodPrompt, setOpenPaymentMethodPrompt] = useState(false);
  const [shopNShipResult, setShopNShipResult] = useRecoilState(
    shopNShipResultState
  );
  const resetShopNShipDetails = useResetRecoilState(shopNShipDetailsState);
  const query = useQuery();
  const [isChinaImport] = useRecoilState(chinaImportState);
  const [openCurrencyPrompt, setOpenCurrencyPrompt] = useState(false);
  const [shopnship] = useRecoilState(shopNShipDetailsState);

  const shippingFrom = shopnship?.shippingFrom?.split(' ')[0];
  const [, setMultipleShopnshipResults] = useRecoilState(
    multipleShopnshipResultsState
  );
  const [
    openBookingSuccessfulPrompt,
    setOpenBookingSuccessfulPrompt
  ] = useState(false);
  const [user] = useRecoilState(userState);
  const [shopnshipRate] = useRecoilState(shopnshipRateState);
  const finalAmount = shopnshipRate?.finalAmount;
  const dollarAmount = Number(shopnshipRate?.amount);
  const shopnshipID = query.get('shopnshipID');

  const shipmentDetail = useMemo(() => {
    const detail = {
      id: shopnship?.id,
      estimatedDeliveryDate: shopnship?.updatedDate,
      updatedDate: shopnship?.updatedDate,
      pricingTier: shopnship.pricingTier ? shopnship.pricingTier : 'Standard',
      senderDetail: {
        name: user?.fullName,
        email: user?.email,
        addressLine1: shopnship?.shippingFrom,
        phoneNumber: user?.phoneNumber,
        state: shippingFrom === 'US' ? 'New York' : 'London',
        countryCode: shippingFrom === 'UK' ? 'GB' : shippingFrom
      },
      receiverDetail: {
        name: shopnship?.deliveryName,
        email: shopnship?.email,
        addressLine1: shopnship?.deliveryAddress,
        phoneNumber: shopnship?.phoneNumber,
        state: 'Lagos',
        countryCode: 'NG'
      },
      items: testJSON(shopnship.itemDescription)
        ? JSON.parse(shopnship?.itemDescription)
        : [
            {
              amountDue: shopnship?.amountDue || '',
              category: shopnship?.category || '',
              estimatedWeight: shopnship?.estimatedWeight || '',
              additionalInformation: shopnship?.itemDescription || '',
              quantity: shopnship?.quantity || '',
              trackingNumber: shopnship?.trackingNumber || ''
            }
          ],
      initialCharge: shopnship?.initialCharge,
      trackingId: shopnship?.trackingId,
      shipmentStatus: shopnship?.status,
      shipmentCharge: shopnship?.initialCharge,
      totalCharge:
        (shopnship.initialCharge || 0) + shopnship?.insuranceCharge * 100,
      insuranceCharge: shopnship?.insuranceCharge * 100,
      insuranceType: shopnship?.insuranceType,
      foreignExchangeCost: shopnship?.foreignExchangeCost,
      foreignExchangeRate: shopnship?.foreignExchangeRate,
      lastMilePricingTier: shopnship?.lastMilePricingTier,
      lastMileCharge: shopnship?.lastMileCharge,
      lastMileVAT: shopnship?.lastMileCharge
        ? calculateVAT(shopnship?.lastMileCharge)
        : 0,
      lastMileTotalCharge: shopnship?.lastMileCharge
        ? shopnship?.lastMileCharge + calculateVAT(shopnship?.lastMileCharge)
        : 0
    };

    if (isChinaImport) {
      detail.totalCharge =
        ((shopnshipRate?.finalAmount ?? 0) +
          (shopnship?.insuranceCharge ?? 0)) *
        100;

      detail.initialCharge = (finalAmount ?? 0) * 100;

      detail.shipmentCharge = (finalAmount ?? 0) * 100;
    }

    return detail;
  }, [shopnship, user, shippingFrom, shopnshipRate]);

  const [saveShopNShip, saveShopNShipResult] = useShopnShipMutation(data => {
    setShopNShipResult(data?.saveShopnship);
    if (data?.saveShopnship?.lastMileShipmentId) {
      setMultipleShopnshipResults([
        {
          isPaid: false,
          id: data?.saveShopnship?.id,
          totalCharge: data?.saveShopnship?.totalCharge ?? 0
        },
        {
          isPaid: false,
          id: data?.saveShopnship?.lastMileShipmentId,
          totalCharge: shipmentDetail?.lastMileTotalCharge
        }
      ]);
      history.push('/shop-and-ship-multiple-payment');
    } else {
      history.replace(
        `/shop-and-ship-payment/?shopnshipID=${
          shopnshipID ? shopnshipID : data?.saveShopnship?.id
        }`
      );
      // setOpenCurrencyPrompt(true);
    }
  });

  const [updateShopnship, updateShopnshipResult] = useUpdateShopnshipMutation(
    data => {
      setShopNShipResult(data?.updateShopnship);
    }
  );

  const [couponData, setCouponData] = useState(null);

  const shippingCost = calculateTotalCost(
    toFixed(shipmentDetail.totalCharge / 100),
    0,
    0,
    shipmentDetail?.lastMileTotalCharge / 100
  );

  const totalCost =
    applyCouponToPrice(
      shippingCost - shipmentDetail?.lastMileTotalCharge / 100,
      couponData ? couponData?.value ?? 0 : 0,
      couponData ? couponData?.type : 'Value'
    ) +
    shipmentDetail?.lastMileTotalCharge / 100;

  const sendAlert = useSendAlert();

  const [getCouponValue, couponResult] = useGetCouponQuery(data => {
    sendAlert('Coupon code applied', 'success');
    setCouponData(data?.getCoupon);
  });

  const pushRoute = index => {
    history.push(filteredLinks[index]?.link);
  };

  const saveShipment = () => {
    if (shopnship.id) {
      setShopNShipResult(shopnship);

      updateShopnship({
        update: { initialCharge: shopnship?.initialCharge },
        id: shopnship.id
      });
    } else {
      delete shopnship.shipmentCharge;
      saveShopNShip({
        shopnship: {
          ...omit(shopnship, ['foreignExchangeCost', 'foreignExchangeRate']),
          insuranceCharge: shopnship?.insuranceCharge * 100,
          initialCharge: isChinaImport
            ? finalAmount * 100
            : shopnship?.initialCharge
        },
        coupon: values.code
      });
    }
  };

  const changeItems = () => {
    history.push(
      filteredLinks.find(link =>
        new RegExp('item information', 'i').test(link.name)
      ).link
    );
  };

  // const removeItem = index => {
  //   const items = testJSON(shopnship.itemDescription)
  //     ? JSON.parse(shopnship?.itemDescription)
  //     : [];

  //   if (items?.length > 1) {
  //     const filteredItems = items.filter((_, itemIndex) => itemIndex !== index);
  //     const itemDescription = JSON.stringify(filteredItems);
  //     setShopnship({ ...shopnship, itemDescription });
  //   }
  // };

  const validationSchema = yup.object().shape({
    code: yup.string().required('Please enter the coupon code')
  });

  const isImportBooking = () => {
    return (
      !shopnship?.id &&
      shopnship?.deliveryState &&
      shopnship?.deliveryState?.toLowerCase() !== 'lagos'
    );
  };
  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleChange,
    resetForm,
    setFieldValue
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: shopnship?.couponApplied || ''
    },
    validationSchema,
    onSubmit: async values => {
      getCouponValue(values.code);
    }
  });

  const removeCoupon = () => {
    resetForm();
    setFieldValue('code', '');
    setCouponData(null);
  };

  useEffect(() => {
    if (shopnship?.couponApplied) {
      getCouponValue(shopnship?.couponApplied);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shopnshipID) {
      setPaymentID(`${shopnshipID}/Initial`);
      setOpenCurrencyPrompt(true);
    }
  }, [shopnshipID, paymentID]);

  const loading = saveShopNShipResult.loading || updateShopnshipResult.loading;
  const currentItemCategory = isChinaImport
    ? itemCategoriesChinaImport
    : itemCategories;

  return (
    <NewShopNShipStepsLayout mobileTitle='Shipment Summary'>
      <PaymentMethodPrompt
        isShopNShip
        openPrompt={openPaymentMethodPrompt}
        setOpenPrompt={setOpenPaymentMethodPrompt}
        paymentID={paymentID}
        transactionType='Shopnship'
        onPromptSuccess={() => {
          setOpenPaymentMethodPrompt(false);
          setOpenBookingSuccessfulPrompt(true);
          resetShopNShipDetails();
        }}
      />

      <SelectCurrencyPrompt
        openPrompt={openCurrencyPrompt}
        setOpenPrompt={setOpenCurrencyPrompt}
        onOpenPaymentPrompt={({ currency }) => {
          setOpenCurrencyPrompt(false);
          // 92897a93-1c93-48be-93fe-8bd105095199
          const shopnshipData =
            updateShopnshipResult?.data?.updateShopnship ||
            saveShopNShipResult?.data?.saveShopnship;

          if (currency === currencies[0].currency) {
            // history.replace(
            //   `/shop-and-ship-summary/?shopnshipID=${
            //     shopnshipID ? shopnshipID : shopnshipData?.id
            //   }`
            // );
            setOpenPaymentMethodPrompt(true);
          } else {
            history.push(
              `/shipment-request-foreign-exchange-payment?shopnshipID=${shopnshipData?.id}/Initial&currency=${currency}`
            );
          }
        }}
      />

      <BookingSuccessfulPrompt
        isChinaImport={isChinaImport}
        handleRoute={() => history.push('/shop-and-ship-delivery-address')}
        openPrompt={openBookingSuccessfulPrompt}
        setOpenPrompt={setOpenBookingSuccessfulPrompt}
      />

      <BusyOverlay loading={loading} />
      <section className='order-summary'>
        <div className='order-summary__wrap'>
          <h2 className='order-summary__header'>
            Review your Shipping information
          </h2>

          <div className='order-summary__shipment'>
            <div className='order-summary__shipment__wrap'>
              <div className='order-summary__shipment__addresses'>
                <div className={`order-summary__shipment__address multiple`}>
                  <div className='order-summary__shipment__address__wrap'>
                    <div className='order-summary__shipment__address__content'>
                      <h4 className='order-summary__shipment__address__label'>
                        PICKUP FROM
                      </h4>
                      <span className='order-summary__shipment__address__name'>
                        {shipmentDetail?.senderDetail?.name || '-'}
                      </span>
                      {isChinaImport ? (
                        <div className='order-summary__item__actions'>
                          <Button disabled variant='text'>
                            <span>
                              Drop off will be available once payment has been
                              received.
                            </span>
                          </Button>
                        </div>
                      ) : (
                        <>
                          <span className='order-summary__shipment__address__location'>
                            {`${
                              shipmentDetail?.senderDetail?.addressLine1 || '-'
                            }, ${shipmentDetail?.senderDetail?.state || '-'}, ${
                              shipmentDetail?.senderDetail?.countryCode || '-'
                            }.`}
                          </span>
                          <span className='order-summary__shipment__address__meta'>
                            {`${shipmentDetail?.senderDetail?.email || '-'} • ${
                              shipmentDetail?.senderDetail?.phoneNumber || '-'
                            }`}
                          </span>
                        </>
                      )}
                    </div>
                    <div className='order-summary__shipment__address__action'>
                      <Button
                        disabled={!!shopnship.id}
                        onClick={() => pushRoute(1)}
                        variant='text'
                      >
                        <span>edit</span>
                        <Edit />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='order-summary__shipment__address deliver-to'>
                  <div className='order-summary__shipment__address__wrap'>
                    <div className='order-summary__shipment__address__content'>
                      <h4 className='order-summary__shipment__address__label'>
                        DELIVER TO
                      </h4>
                      <span className='order-summary__shipment__address__name'>
                        {shipmentDetail?.receiverDetail?.name || '-'}
                      </span>

                      <span className='order-summary__shipment__address__location'>
                        {`${
                          shipmentDetail?.receiverDetail?.addressLine1 || '-'
                        }.`}
                      </span>
                      <span className='order-summary__shipment__address__meta'>
                        {`${shipmentDetail?.receiverDetail?.email || '-'} • ${
                          shipmentDetail?.receiverDetail?.phoneNumber || '-'
                        }`}
                      </span>
                    </div>
                    <div className='order-summary__shipment__address__action'>
                      <Button
                        disabled={!!shopnship.id}
                        onClick={() => pushRoute(1)}
                        variant='text'
                      >
                        <span>edit</span>
                        <Edit />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='order-summary__shipment__meta'>
                <div className='order-summary__shipment__meta__wrap'>
                  <div className='order-summary__shipment__meta__content'>
                    <span className='order-summary__shipment__meta__content__label'>
                      EXCHANGE RATE
                    </span>
                    <span className='order-summary__shipment__meta__content__value'>
                      {`${
                        toCurrency(
                          1,
                          shippingFrom === 'UK' ? 'GBP' : 'USD'
                        ).split('.')[0]
                      }/${
                        isChinaImport
                          ? toCurrency(finalAmount / dollarAmount)
                          : toCurrency(shipmentDetail?.foreignExchangeRate)
                      }`}
                    </span>
                  </div>
                  <div className='order-summary__shipment__meta__content'>
                    <span className='order-summary__shipment__meta__content__label'></span>
                    <span className='order-summary__shipment__meta__content__value'></span>
                  </div>
                  <div className='order-summary__shipment__meta__action'></div>
                </div>
              </div>
              <div className='order-summary__shipment__meta'>
                <div className='order-summary__shipment__meta__wrap'>
                  <div className='order-summary__shipment__meta__content'>
                    <span className='order-summary__shipment__meta__content__label'>
                      AMOUNT IN {shippingFrom === 'UK' ? 'POUNDS' : 'DOLLARS'}
                    </span>
                    <span className='order-summary__shipment__meta__content__value'>
                      {isChinaImport
                        ? toCurrency(dollarAmount, 'USD')
                        : toCurrency(
                            shipmentDetail?.foreignExchangeCost,
                            shippingFrom === 'UK' ? 'GBP' : 'USD'
                          )}
                    </span>
                  </div>
                  <div className='order-summary__shipment__meta__content'>
                    <span className='order-summary__shipment__meta__content__label'>
                      AMOUNT IN NAIRA
                    </span>
                    <span className='order-summary__shipment__meta__content__value'>
                      {isChinaImport
                        ? toCurrency(finalAmount)
                        : toCurrency(shipmentDetail.shipmentCharge / 100 || 0)}
                    </span>
                  </div>
                  <div className='order-summary__shipment__meta__action'></div>
                </div>
              </div>
              <ItemInfoComponent
                label={'SHIPPING METHOD'}
                value={getPlan('shopnship', shipmentDetail?.pricingTier)?.name}
                cost={
                  isChinaImport
                    ? toCurrency(dollarAmount, 'USD')
                    : toCurrency(
                        shipmentDetail?.foreignExchangeCost,
                        shippingFrom === 'UK' ? 'GBP' : 'USD'
                      )
                }
                pushRoute={() => pushRoute(3)}
              />
              {isImportBooking() ? (
                <ItemInfoComponent
                  label={'DELIVERY METHOD'}
                  value={
                    getPlan('lastMile', shipmentDetail?.lastMilePricingTier)
                      ?.name
                  }
                  cost={toCurrency(
                    toFixed(shipmentDetail?.lastMileCharge / 100)
                  )}
                  pushRoute={() => pushRoute(3)}
                />
              ) : null}
              <ItemInfoComponent
                label={'INSURANCE COVER'}
                value={
                  getPlan('insurance', shipmentDetail?.insuranceType)?.name ||
                  '-'
                }
                cost={
                  shipmentDetail.insuranceCharge
                    ? toCurrency(shipmentDetail.insuranceCharge / 100)
                    : 'FREE'
                }
                pushRoute={() => pushRoute(4)}
              />

              <div className='order-summary__shipment__meta'>
                <div className='order-summary__shipment__meta__wrap'>
                  <div className='order-summary__shipment__meta__content'>
                    <span className='order-summary__shipment__meta__content__label'>
                      PAYMENT DUE
                    </span>
                    <span className='order-summary__shipment__meta__content__value'>
                      {toCurrency(totalCost)}

                      {/* {toCurrency(shipmentDetail.totalCharge / 100 || 0)} */}
                    </span>
                  </div>
                  <div className='order-summary__shipment__meta__content'>
                    <span className='order-summary__shipment__meta__content__label'>
                      {shipmentDetail?.lastMileCharge
                        ? 'VAT ON LAST MILE SHIPMENT'
                        : ''}
                    </span>
                    <span className='order-summary__shipment__meta__content__value'>
                      {shipmentDetail?.lastMileCharge
                        ? toCurrency(shipmentDetail?.lastMileVAT / 100)
                        : ''}
                    </span>
                  </div>
                  <div className='order-summary__shipment__meta__action'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='order-summary__items'>
            {shipmentDetail.items.map((item, index) => (
              <div key={index} className='order-summary__item'>
                <div className='order-summary__item__wrap'>
                  <div className='order-summary__item__actions'>
                    {/* {shipmentDetail?.items?.length > 1 && (
                      <Button
                        disabled={!!shopnship.id}
                        variant='text'
                        onClick={() => removeItem(index)}
                      >
                        <span>Remove item</span>
                        <RemoveCircle />
                      </Button>
                    )} */}
                    <Button
                      disabled={!!shopnship.id}
                      onClick={changeItems}
                      variant='text'
                      sx={{ opacity: !!shopnship.id ? '0.4' : '1' }}
                    >
                      <span>change</span>
                      <Edit />
                    </Button>
                  </div>
                  <div className='order-summary__item__rows'>
                    <div className='order-summary__item__row'>
                      <div className='order-summary__item__row__wrap'>
                        <div className='order-summary__item__row__content'>
                          <span className='order-summary__item__row__content__label'>
                            ITEM CATEGORY
                          </span>
                          <span className='order-summary__item__row__content__value'>
                            {currentItemCategory.find(
                              category => category?.value === item?.category
                            )?.category || '-'}
                          </span>
                        </div>
                        <div className='order-summary__item__row__content'>
                          <span className='order-summary__item__row__content__label'>
                            ITEM VALUE
                          </span>
                          <span className='order-summary__item__row__content__value'>
                            {isChinaImport && !shopnship?.id
                              ? toCurrency(item?.amountDue || 0, 'USD')
                              : toCurrency(item?.amountDue || 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='order-summary__item__row'>
                      <div className='order-summary__item__row__wrap'>
                        <div className='order-summary__item__row__content'>
                          <span className='order-summary__item__row__content__label'>
                            ITEM DESCRIPTION
                          </span>
                          <span
                            style={{ lineHeight: '24px' }}
                            className='order-summary__item__row__content__value'
                          >
                            {item?.additionalInformation || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='order-summary__item__row'>
                      <div className='order-summary__item__row__wrap'>
                        <div className='order-summary__item__row__content'>
                          <span className='order-summary__item__row__content__label'>
                            QUANTITY
                          </span>
                          <span className='order-summary__item__row__content__value'>
                            {item?.quantity || '-'}
                          </span>
                        </div>
                        <div className='order-summary__item__row__content'>
                          <span className='order-summary__item__row__content__label'>
                            WEIGHT
                          </span>
                          <span className='order-summary__item__row__content__value'>
                            {`${item?.estimatedWeight || 0} KG`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='order-summary__coupon'>
            <div className='order-summary__coupon__wrap'>
              {couponResult.loading ? (
                <>
                  <h4 className='mt-1 mb-1'>Applying...</h4>
                  <div className={`delivery-options__item`}>
                    <div className='delivery-options__item__wrap discount'>
                      <div className='delivery-options__item__content w-100'>
                        <div className='delivery-options__item__address w-100'>
                          <Skeleton className='w-100' />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : couponData ? (
                <>
                  <div className='mt-1 mb-1 d-flex justify-content-between'>
                    <h4 className=''>Coupon code applied</h4>
                    {/* {Boolean(!shopnship?.couponApplied) && !shopnship?.id && ( */}
                    <div className='item-description__main__action'>
                      <Button variant='text' onClick={removeCoupon}>
                        <span>Erase coupon</span>
                        <RemoveCircle />
                      </Button>
                    </div>
                    {/* )} */}
                  </div>
                  <div className={`delivery-options__item active`}>
                    <div className='delivery-options__item__wrap discount'>
                      <div className='delivery-options__item__content'>
                        <div
                          style={{ textDecoration: 'line-through' }}
                          className='delivery-options__item__address'
                        >
                          <span>{toCurrency(shippingCost)}</span>
                        </div>
                      </div>
                      <div className='delivery-options__item__price discount'>
                        <span>{toCurrency(totalCost)}</span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Input
                  disabled={!!shopnship.id}
                  variant='standard'
                  customLabel='Coupon Code'
                  type='text'
                  id='code'
                  name='code'
                  onChange={handleChange}
                  className='inputGroup2__inputs__input'
                  placeholder='Got a code?'
                  value={values.code}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <div className='order-summary__shipment__address__action'>
                          <Button
                            disabled={!!shopnship.id}
                            onClick={handleSubmit}
                            variant='text'
                          >
                            <span>Apply</span>
                          </Button>
                        </div>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            </div>
          </div>

          <div className='order-summary__terms'>
            <div className='order-summary__terms__wrap'>
              <div
                className='mt-2'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => setAgreement(!agreement)}
              >
                <div
                  style={{
                    width: '30px',
                    height: '24px',
                    borderRadius: '6px',
                    backgroundColor: agreement ? '#6B50F6' : 'transparent',
                    border: '2px solid #ddd',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                    // marginRight: '8px'
                  }}
                >
                  {agreement && (
                    <img width={20} src={WhiteCheck} alt='white check' />
                  )}
                </div>
                <label
                  className='mb-0'
                  style={{
                    color: 'black',
                    fontSize: '14px',
                    lineHeight: '22px',
                    marginLeft: '10px'
                  }}
                >
                  By clicking Confirm, I acknowledge that I have read,
                  understood and agreed to the Topship’s{' '}
                  <Link to='/terms' target='_blank'>
                    {' '}
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link to='/terms' target='_blank'>
                    Terms of Service
                  </Link>
                </label>
                {/* </div> */}
              </div>
            </div>
            <div className='order-summary__footer'>
              <div
                onClick={() =>
                  history.push(filteredLinks[currentIndex - 1].link)
                }
                disabled={!!shopnship?.id}
                className='order-summary__footer__action'
              >
                Go Back
              </div>

              <SubmitButton
                disabled={!agreement}
                onClick={saveShipment}
                text={`Confirm`}
              />
            </div>
          </div>
        </div>
      </section>
    </NewShopNShipStepsLayout>
  );
};

export default ShopnShipSummary;
