import axios from 'axios';
import gql from 'graphql-tag';

const GET_WALLET_BALANCE = gql`
  query {
    getWalletBalance {
      totalBalance
      bonusBalance
    }
  }
`;

const GET_TRANSACTION_REFERENCE = gql`
  query getReference($transaction: initTransactionInput!) {
    generateTransactionReference(referenceDetail: $transaction) {
      id
    }
  }
`;

const USER_ROUNDUP_DATA = gql`
  query {
    getUserRoundupData {
      topCitiesShippedTo {
        city
        count
      }
      numberOfShipments
      topServicesUsed {
        service
        count
      }
      userPercentage
    }
  }
`;

const GET_USER = gql`
  query getUser($sub: String!) {
    user(userId: $sub) {
      topshipId
      id
      fullName
      phoneNumber
      email
      isEmailVerified
      displayPictureUrl
      accountType
      vibStatus
      createdDate
      percentageCommissionOnUser
      referredUsers {
        topshipId
        displayPictureUrl
        fullName
        createdDate
      }
      businessInformation {
        id
        logo
        businessName
        isVerified
        compliance
        location {
          address
          state
          city
        }
        directors {
          fullName
          compliance
        }
      }
    }
  }
`;

const GET_WALLET = gql`
  query {
    getWallet {
      id
      totalReceived
      totalSent
    }
  }
`;

const GET_TRANSACTIONS = gql`
  query getTransactions($filter: TransactionFilter) {
    getTransactions(filter: $filter) {
      totalPages
      totalCount
      currentPage
      list {
        id
        transactionId
        amount
        transactionType
        description
        transactionStatus
        updatedDate
        shipment {
          id
        }
      }
    }
  }
`;

const GET_CURRENT_MONTH_SHIPMENTS = gql`
  query getCurrentMonthShipments($shipmentType: String!) {
    getCurrentMonthShipments(shipmentType: $shipmentType) {
      currentMonthShipments
      currentMonthRevenue
      percentageDifferenceOnRevenue
      percentageDifferenceOnShipments
    }
  }
`;

const GET_PICKUP_RATES = gql`
  query getPickupRates($input: PickupRatesInput!) {
    getPickupRates(input: $input) {
      partner
      pickupCharge
      duration
      pricingTier
      deliveryLocation
      partnerLogoUrl
      pickupId
    }
  }
`;

const GET_LAST_MILE_RATES = gql`
  query getLastMileRates($input: LastMileRatesInput!) {
    getLastMileRates(input: $input) {
      mode
      cost
      pricingTier
      duration
      logo
    }
  }
`;

const GET_SHIPMENTS = gql`
  query getShipments($filter: ShipmentFilter) {
    getShipments(filter: $filter) {
      totalCount
      currentPage
      list {
        id
        pricingTier
        additionalInformation
        createdDate
        updatedDate
        estimatedDeliveryDate
        trackingId
        itemCollectionMode
        shipmentStatus
        insuranceType
        isPaid
        totalCharge
        insuranceCharge
        pickupCharge
        pickupDate
        shipmentCharge
        shipmentRoute
        budgetDeliveryCharge
        items {
          category
          description
          weight
          quantity
          value
          boxNumber
        }
        senderDetail {
          name
          email
          phoneNumber
          addressLine1
          state
          city
          postalCode
          country
          countryCode
        }
        receiverDetail {
          name
          email
          phoneNumber
          addressLine1
          state
          city
          postalCode
          country
          countryCode
        }
        documents {
          id
          name
          content
          isVerified
        }
      }
    }
  }
`;

const GET_SHIPMENT_DETAILS = gql`
  query getShipment($shipmentId: String!) {
    getShipment(id: $shipmentId) {
      id
      pricingTier
      updatedDate
      trackingId
      itemCollectionMode
      shipmentStatus
      insuranceType
      isPaid
      totalCharge
      insuranceCharge
      senderDetail {
        addressLine1
        addressLine2
        addressLine3
        state
        country
        name
        email
        phoneNumber
      }
      receiverDetail {
        addressLine1
        addressLine2
        addressLine3
        state
        country
        name
        email
        phoneNumber
      }
    }
  }
`;

const GET_SAVED_ADDRESSES = gql`
  query getAddresses($filter: AddressFilter) {
    getAddresses(filter: $filter) {
      totalCount
      currentPage
      list {
        id
        name
        email
        isDefault
        phoneNumber
        addressLine1
        addressLine2
        addressLine3
        city
        country
        state
        countryCode
        updatedDate
        postalCode
      }
    }
  }
`;

const GET_SHIPMENT_RATES = gql`
  query getShipmentRates($shipmentDetail: GetShipmentRateInput!) {
    getShipmentRate(shipmentDetail: $shipmentDetail) {
      mode
      cost
      actualCost
      remoteAreaCost
      duration
      currency
      pricingTier
      isDisabled
      isRecommended
      budgetDeliveryCost
    }
  }
`;

const GET_SHOPNSHIP_RATES = gql`
  query getShopnshipRates($input: GetShopnshipRatesInput!) {
    getShopnshipRates(input: $input) {
      mode
      cost
      actualCost
      remoteAreaCost
      duration
      currency
      pricingTier
      isDisabled
      isRecommended
      budgetDeliveryCost
      foreignExchangeCost
      foreignExchangeRate
    }
  }
`;

const TRACK_SHIPMENT = gql`
  query trackShipment($trackingId: String!) {
    trackShipment(trackingId: $trackingId) {
      id
      status
      message
      createdDate
      updatedDate
      shipment {
        receiverDetail {
          name
          addressLine1
          postalCode
        }
      }
    }
  }
`;

const GET_BANK_LIST = gql`
  query {
    getBankList {
      name
      code
    }
  }
`;

const GET_BANK_ACCOUNT_NAME = gql`
  query getBankAccountName($bankDetail: GetBankAccountNameInput!) {
    getBankAccountName(bankDetail: $bankDetail) {
      accountName
    }
  }
`;

const GET_BANK_ACCOUNTS = gql`
  query {
    getUserBankDetails {
      accountName
      accountNumber
      bankName
      createdDate
      id
    }
  }
`;

const GET_WITHDRAWAL_LOGS = gql`
  query getUserWithdrawalRequests($filter: WithdrawalRequestFilter) {
    getUserWithdrawalRequests(filter: $filter) {
      list {
        amount
        createdDate
        approvalStatus
        bankDetail {
          id
        }
      }
    }
  }
`;

const GET_COUPON = gql`
  query getCoupon($code: String!) {
    getCoupon(code: $code) {
      id
      code
      value
      type
      limit
      isActive
    }
  }
`;

const GET_COUNTRIES = gql`
  query {
    getCountries {
      code
      name
    }
  }
`;

const GET_STATES = gql`
  query getStates($countryCode: String!) {
    getStates(countryCode: $countryCode) {
      name
      countryCode
    }
  }
`;

const GET_CITIES = gql`
  query getCities($countryCode: String!) {
    getCities(countryCode: $countryCode) {
      cityName
      postcode
    }
  }
`;

const GET_SHOPNSHIP = gql`
  query getShopnship($id: String!) {
    getShopnship(id: $id) {
      id
      initialCharge
      totalCharge
      finalCharge
      itemDescription
      proofOfId
      evidenceOfPurchase
      couponApplied
      couponAppliedAmount
      insuranceCharge
      insuranceType
      user {
        id
        topshipId
        email
      }
      transactions {
        transactionId
      }
    }
  }
`;

const GET_SHOPNSHIPS = gql`
  query getShopnships($filter: ShopnshipFilter) {
    getShopnships(filter: $filter) {
      totalPages
      totalCount
      currentPage
      list {
        id
        createdDate
        updatedDate
        email
        phoneNumber
        shippingFrom
        packageName
        trackingNumber
        deliveryName
        deliveryAddress
        deliveryState
        deliveryCity
        estimatedWeight
        itemDescription
        pricingTier
        amountDue
        receiverPaymentDetails
        uploadingMyDocuments
        trackingId
        status
        initialCharge
        isInitialChargePaid
        finalCharge
        isFinalChargePaid
        discount
        totalCharge
        proofOfId
        evidenceOfPurchase
        category
        couponApplied
        couponAppliedAmount
        insuranceCharge
        insuranceType
        user {
          id
          topshipId
          email
          fullName
        }
      }
    }
  }
`;

const GET_USER_SHOPNSHIP_FINAL_CHARGES = gql`
  query {
    getUserShopnshipFinalCharges {
      id
      deliveryName
      email
      updatedDate
      actualWeight
      estimatedWeight
      shippingFrom
      trackingId
      status
      amountDue
      initialCharge
      isInitialChargePaid
      finalCharge
      isFinalChargePaid
      discount
      totalCharge
    }
  }
`;

const GET_FOREIGN_RATES = async ({ from, amount = 1 }) => {
  return await axios.get(
    `${process.env.REACT_APP_REST_API}/rate-converter?from=${from}&to=NGN&amount=${amount}`
  );
};

const GET_SHOPNSHIP_CURRENCY_RATES = async ({ shippingFrom, chinaCost }) => {
  const from = shippingFrom === 'UK' ? 'GBP' : 'USD';

  const amount = chinaCost || 20;

  return await GET_FOREIGN_RATES({ from, amount });
};

const GET_LOGISTICS_BUSINESS_CONFIGS = gql`
  query {
    getLogisticsBusinessConfigs {
      id
      service
      additionalCharge
      hasEmailNotification
      hasSmsNotification
      createdDate
      updatedDate
      route
    }
  }
`;

const GET_BUSINESS_INFORMATION = gql`
  query getBusinessInformationByUser($topshipId: String!) {
    getBusinessInformationByUser(topshipId: $topshipId) {
      id
      logo
      businessName
    }
  }
`;

const GET_SHIPMENT_QUOTE = gql`
  query getShipmentQuote(
    $rate: RateInput!
    $shipmentDetail: GetShipmentRateInput!
  ) {
    getShipmentQuote(rate: $rate, shipmentDetail: $shipmentDetail) {
      file
      date
    }
  }
`;

const GET_PAYMENT_INTENT = gql`
  query getPaymentIntent($input: GetPaymentIntentInput!) {
    getPaymentIntent(input: $input) {
      clientSecret
      foreignExchangeCharge
    }
  }
`;

const GET_VIRTUAL_BANK_ACCOUNT = gql`
  query generateVirtualBankAccount($input: GenerateVirtualBankAccountInput!) {
    generateVirtualBankAccount(input: $input) {
      checkoutUrl
    }
  }
`;

const GET_SESSION = gql`
  query generateSession($input: GenerateSessionInput!) {
    generateSession(input: $input) {
      id
      sessionData
    }
  }
`;

const GET_PAYPAL_CHEKOUT_URL = gql`
  query generatePayPalCheckoutUrl($input: GetPaymentIntentInput!) {
    generatePayPalCheckoutUrl(input: $input)
  }
`;

export {
  GET_PAYPAL_CHEKOUT_URL,
  GET_SESSION,
  GET_WALLET_BALANCE,
  GET_TRANSACTION_REFERENCE,
  GET_USER,
  GET_WALLET,
  GET_TRANSACTIONS,
  GET_SHIPMENTS,
  GET_SHIPMENT_DETAILS,
  GET_SAVED_ADDRESSES,
  GET_CURRENT_MONTH_SHIPMENTS,
  GET_SHIPMENT_RATES,
  TRACK_SHIPMENT,
  GET_BANK_LIST,
  GET_BANK_ACCOUNT_NAME,
  GET_BANK_ACCOUNTS,
  GET_WITHDRAWAL_LOGS,
  GET_COUPON,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_SHOPNSHIP,
  GET_SHOPNSHIPS,
  GET_USER_SHOPNSHIP_FINAL_CHARGES,
  GET_LOGISTICS_BUSINESS_CONFIGS,
  USER_ROUNDUP_DATA,
  GET_SHOPNSHIP_CURRENCY_RATES,
  GET_FOREIGN_RATES,
  GET_BUSINESS_INFORMATION,
  GET_SHIPMENT_QUOTE,
  GET_PAYMENT_INTENT,
  GET_VIRTUAL_BANK_ACCOUNT,
  GET_SHOPNSHIP_RATES,
  GET_PICKUP_RATES,
  GET_LAST_MILE_RATES
};
