import React, { useEffect, useMemo, useState } from 'react';
import {
  useGenerateTransactionReferenceQuery,
  useGetCouponQuery,
  useGetShipmentDetailsQuery,
  useGetShopnshipQuery,
  useGetVirtualBankAccount,
  useGetWalletBalanceQuery
} from '../../operations/queries';
import { toCurrency } from '../../utilities/to-currency';
import Client from '../../services/client';
import { useRecoilState } from 'recoil';
import {
  selectedPaymentMethod,
  shopNShipResultState
} from '../../recoil/atoms';
import { useSendAlert } from '../../utilities/send-alert';
import { useGetUserPayload } from '../../utilities/get-payload';
import {
  usePayAdditionalChargeByWalletMutation,
  usePayFromWalletMutation,
  useUpdateShipmentMutation,
  useUpdateShopnshipMutation
} from '../../operations/mutations';
import { transactionTypesEnum } from '../../utilities/enums/transaction-types.enum';
import paymentMethods from '../../constants/payment-method';
import { usePaystackPayment } from 'react-paystack';
import CustomSpinner from '../custom-spinner';
import { ChevronLeft, ContentCopy, InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useHandleCopyLink } from '../../utilities/copy-link';
import { currencies } from '../../constants/currencies';
import BusyOverlay from '../busy-overlay';

const ONE_MILLION = 1_000_000;
const PaymentMethodPrompt = ({
  openPrompt,
  setOpenPrompt,
  paymentID,
  onPromptSuccess,
  isShopNShip = false,
  isPendingShopnship = false,
  transactionType,
  pendingChargeAmount,
  amountToFund,
  isMultipleShipmentBankTransfer = false,
  resolveBankTransfer,
  multipleShipmentAmount = 0,
  onClosePrompt
}) => {
  const client = Client();
  const [paymentMethod, setPaymentMethod] = useRecoilState(
    selectedPaymentMethod
  );
  const [, setCouponData] = useState(null);
  const walletBalance = useGetWalletBalanceQuery();
  const [shopNShipResult, setShopNShipResult] = useRecoilState(
    shopNShipResultState
  );
  const [showBankInfo, setShowBankInfo] = useState(false);
  const sendAlert = useSendAlert();
  const userPayload = useGetUserPayload();
  const [
    getVirtualBankAccount,
    getVirtualBankAccountResult
  ] = useGetVirtualBankAccount(data => {
    if (data?.generateVirtualBankAccount?.checkoutUrl) {
      window.location.replace(data?.generateVirtualBankAccount?.checkoutUrl);
    }
  });

  const [updateShipment, updateShipmentResult] = useUpdateShipmentMutation(
    data => {
      if (data) {
        onPromptSuccess();
        // if (paymentMethod.value === paymentMethods[2].value) {
        //   window.location.href = 'mailto:tech@topship.africa';
        // }
      }
    }
  );

  const [updateShopnship, updateShopnshipResult] = useUpdateShopnshipMutation(
    data => {
      if (data) {
        onPromptSuccess();
        // if (paymentMethod.value === paymentMethods[2].value) {
        //   window.location.href = 'mailto:tech@topship.africa';
        // }
      }
    }
  );

  const markAsPending = () => {
    if (isShopNShip || isPendingShopnship) {
      return updateShopnship({
        id: paymentID?.split?.('/')?.[0],
        update: { status: 'PaymentPending' }
      });
    }

    updateShipment(paymentID, { shipmentStatus: 'PaymentPending' });
  };

  const [
    generateReference,
    { loading, data }
  ] = useGenerateTransactionReferenceQuery();

  const [
    getShipment,
    { data: shipmentData, loading: shipmentLoading }
  ] = useGetShipmentDetailsQuery();

  const [getCouponValue] = useGetCouponQuery(data => {
    setCouponData(data?.getCoupon);
  });

  const [getShopnship] = useGetShopnshipQuery(data => {
    setShopNShipResult(data?.getShopnship);
  });

  const [
    payFromWalletById,
    {
      loading: walletPaymentLoading,
      data: walletPaymentData,
      error: walletPaymentError
    }
  ] = usePayFromWalletMutation();

  const [
    payAdditionalChargeById,
    {
      loading: additionalPaymentLoading,
      data: additionalPaymentData,
      error: additionalPaymentError
    }
  ] = usePayAdditionalChargeByWalletMutation(data => {
    onSuccess();
  });

  const pageLoading =
    loading ||
    shipmentLoading ||
    walletPaymentLoading ||
    additionalPaymentLoading ||
    updateShipmentResult.loading ||
    updateShopnshipResult.loading ||
    getVirtualBankAccountResult.loading;

  const handlePaymentMethodSelection = type => {
    setPaymentMethod(type);

    if (type.value === 'Card') {
      if (transactionType === transactionTypesEnum.AdditionalCharge) {
        generateReference(paymentID, transactionTypesEnum.AdditionalCharge);
      } else if (transactionType === transactionTypesEnum.deposit) {
        generateReference(paymentID, transactionTypesEnum.deposit);
      } else {
        generateReference(paymentID, transactionTypesEnum.cardPayment);
      }
    }

    // bank transfer
    // if (type.value === paymentMethods[2].value) {
    //   setShowBankInfo(true);
    // }
  };

  const onSuccess = reference => {
    sendAlert('Payment successful!');

    client.resetStore();

    setPaymentMethod({ value: '' });

    if (
      // paymentMethod.value !== paymentMethods[0].value &&
      // paymentMethod.value !== paymentMethods[2].value &&

      paymentMethod.value === paymentMethods[1].value &&
      transactionType !== transactionTypesEnum.deposit
    ) {
      markAsPending();
    } else {
      onPromptSuccess();
    }
    setOpenPrompt(false);
  };

  const onClose = () => {
    setOpenPrompt(false);

    setPaymentMethod({ value: '' });

    if (onClosePrompt) {
      onClosePrompt();
    }

    if (
      transactionType !== transactionTypesEnum.AdditionalCharge &&
      !isMultipleShipmentBankTransfer
    ) {
      if (transactionType === transactionTypesEnum.deposit) {
        return window.location.reload();
      }

      window.location.href = '/';
    }
  };

  const amount = useMemo(() => {
    if (transactionType === 'Shipment') {
      return shipmentData?.getShipment?.totalCharge / 100 || 0;
    }

    if (transactionType === 'Shopnship') {
      if (isPendingShopnship) {
        return shopNShipResult?.finalCharge / 100 || 0;
      } else if (isShopNShip) {
        return shopNShipResult?.totalCharge / 100 || 0;
      }
    }

    if (amountToFund && transactionType === transactionTypesEnum.deposit) {
      return amountToFund;
    }

    if (multipleShipmentAmount && isMultipleShipmentBankTransfer) {
      return multipleShipmentAmount;
    }

    if (transactionType === transactionTypesEnum.AdditionalCharge) {
      return pendingChargeAmount / 100 || 0;
    }

    return 0;
  }, [
    amountToFund,
    isShopNShip,
    shipmentData,
    shopNShipResult,
    transactionType,
    isPendingShopnship,
    pendingChargeAmount,
    multipleShipmentAmount,
    isMultipleShipmentBankTransfer
  ]);

  const config = useMemo(
    () => ({
      amount: amount * 100,
      email: userPayload.username,
      publicKey: process.env.REACT_APP_PAYSTACK_PUB_KEY,
      reference: data && data.generateTransactionReference.id
    }),
    [data, userPayload, amount]
  );

  const initializePayment = usePaystackPayment(config);

  const handlePayment = () => {
    if (isMultipleShipmentBankTransfer) {
      return resolveBankTransfer();
    }
    if (paymentMethod.value === 'Card') {
      initializePayment(onSuccess, onClose);
    }
    // else if (paymentMethod.value === paymentMethods[3].value) {
    //   if (transactionType === transactionTypesEnum.AdditionalCharge) {
    //     return onPromptSuccess();
    //   }

    //   markAsPending();
    // }
    else if (paymentMethod.value === paymentMethods[2].value) {
      if (paymentID) {
        const payload = {
          id: paymentID,
          redirectUrl: `${window.location.origin}/shipment-payment-successful?shipmentID=${paymentID}`
        };

        if (transactionType === transactionTypesEnum.deposit) {
          delete payload.id;

          payload.amount = amountToFund * 100;

          payload.redirectUrl = `${window.location.origin}/wallet/wallet-funding-successful`;
        }

        if (transactionType === transactionTypesEnum.AdditionalCharge) {
          payload.redirectUrl = `${window.location.origin}/pending-charges`;
        }

        if (isShopNShip || isPendingShopnship) {
          payload.redirectUrl = `${
            window.location.origin
          }/shop-and-ship-successful?shopnshipID=${paymentID.split('/')[0]}`;
        }

        getVirtualBankAccount(payload);
      }
    } else {
      if (transactionType === transactionTypesEnum.AdditionalCharge) {
        payAdditionalChargeById(paymentID);
      } else {
        if (transactionType !== transactionTypesEnum.deposit) {
          payFromWalletById(paymentID);
        }
      }
    }
  };

  const handleCopy = useHandleCopyLink();

  useEffect(() => {
    if (
      (walletPaymentData && !walletPaymentError) ||
      (additionalPaymentData && !additionalPaymentError)
    ) {
      onSuccess('');
    }
    // eslint-disable-next-line
  }, [walletPaymentData]);

  useEffect(() => {
    if (!isMultipleShipmentBankTransfer) {
      if (
        !Object.values(shopNShipResult).length &&
        paymentID?.split('/')?.length > 1 &&
        !isPendingShopnship
      ) {
        getShopnship(paymentID?.split('/')[0]);
      }
      if (shopNShipResult?.couponApplied) {
        getCouponValue(shopNShipResult?.couponApplied);
      }
    }
    //eslint-disable-next-line
  }, [
    paymentID,
    shopNShipResult.couponApplied,
    isPendingShopnship,
    isMultipleShipmentBankTransfer
  ]);

  useEffect(() => {
    if (paymentID && transactionType === 'Shipment') {
      getShipment(paymentID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentID]);

  return (
    <>
      <div
        className={`new-shop-n-ship__prompt fade${openPrompt ? ' show' : ''}`}
      >
        <BusyOverlay loading={pageLoading} />

        <div className='new-shop-n-ship__prompt__wrap'>
          <div className='new-shop-n-ship__prompt__header'>
            <h2>
              {showBankInfo || isMultipleShipmentBankTransfer ? (
                <div className='d-flex align-items-center'>
                  {!isMultipleShipmentBankTransfer && (
                    <IconButton
                      sx={{ color: '#22428f' }}
                      onClick={() => {
                        setPaymentMethod({ value: '' });
                        setShowBankInfo(false);
                      }}
                    >
                      <ChevronLeft />
                    </IconButton>
                  )}
                  <span>Bank Transfer</span>
                </div>
              ) : (
                `Payment Method ${currencies[0].logo}`
              )}
            </h2>
          </div>
          <div className='new-shop-n-ship__prompt__body'>
            <div className='new-shop-n-ship__prompt__body__wrap'>
              <div className='new-shop-n-ship__prompt__body__options'>
                <div className='new-shop-n-ship__prompt__body__options__wrap'>
                  <div className='new-shop-n-ship__prompt__body__options__header'>
                    <span>
                      {showBankInfo || isMultipleShipmentBankTransfer ? (
                        <span>
                          Transfer <b>{toCurrency(amount)}</b> to the account
                          below
                        </span>
                      ) : (
                        <>
                          Pick a payment method to pay{' '}
                          <span style={{ color: '#10C54E' }}>
                            {toCurrency(amount)}
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                  {showBankInfo || isMultipleShipmentBankTransfer ? (
                    <div className='payment-method__bank-transfer'>
                      <div className='payment-method__bank-transfer__wrap'>
                        <div className='payment-method__bank-transfer__item'>
                          <div className='payment-method__bank-transfer__item__wrap'>
                            <div className='payment-method__bank-transfer__item__content'>
                              <h3 className='payment-method__bank-transfer__item__name'>
                                Bank Name
                              </h3>
                              <p className='payment-method__bank-transfer__item__value'>
                                Moniepoint
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='payment-method__bank-transfer__item'>
                          <div className='payment-method__bank-transfer__item__wrap'>
                            <div className='payment-method__bank-transfer__item__content'>
                              <h3 className='payment-method__bank-transfer__item__name'>
                                Account Name
                              </h3>
                              <p className='payment-method__bank-transfer__item__value'>
                                Topship Ltd Shipment
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='payment-method__bank-transfer__item'>
                          <div className='payment-method__bank-transfer__item__wrap'>
                            <div className='payment-method__bank-transfer__item__content'>
                              <h3 className='payment-method__bank-transfer__item__name'>
                                Account Number
                              </h3>
                              <p className='payment-method__bank-transfer__item__value'>
                                6357419864
                              </p>
                            </div>
                            <IconButton
                              onClick={() => handleCopy('6357419864')}
                            >
                              <ContentCopy />
                            </IconButton>
                          </div>
                        </div>
                        <div className='payment-method__bank-transfer__item'>
                          <div className='payment-method__bank-transfer__item__wrap'>
                            <div className='payment-method__bank-transfer__item__content'>
                              <h3 className='payment-method__bank-transfer__item__name'>
                                Amount
                              </h3>
                              <p className='payment-method__bank-transfer__item__value'>
                                {toCurrency(amount)}
                              </p>
                            </div>
                            <IconButton onClick={() => handleCopy(amount)}>
                              <ContentCopy />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='new-shop-n-ship__prompt__body__options__list'>
                      {paymentMethods
                        .filter((_, index) => {
                          if (amount >= ONE_MILLION) {
                            return index !== 1;
                          }

                          if (
                            transactionType === transactionTypesEnum.deposit
                          ) {
                            // return [1, 2].includes(index);
                            return index > 0;
                          }

                          return true;
                        })
                        .map(method => (
                          <div
                            key={method.value}
                            onClick={() => handlePaymentMethodSelection(method)}
                            className={`new-shop-n-ship__prompt__body__options__list__item${
                              paymentMethod.name === method.name
                                ? ' active'
                                : ''
                            }`}
                          >
                            <div className='new-shop-n-ship__prompt__body__options__list__item__wrap'>
                              <div className='new-shop-n-ship__prompt__body__options__list__item__name'>
                                <h3
                                  style={{
                                    color: '#22428f',
                                    fontWeight: 800,
                                    textTransform: 'capitalize'
                                  }}
                                >
                                  {method.name}
                                </h3>
                              </div>
                              <div className='new-shop-n-ship__prompt__body__options__list__item__address'>
                                <span>
                                  {method.value === 'Wallet'
                                    ? `Current Balance : ${toCurrency(
                                        walletBalance.data &&
                                          walletBalance.data.getWalletBalance
                                            .totalBalance / 100
                                      )}`
                                    : method.cost}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              {showBankInfo && (
                <div className='payment-method__bank-transfer__notice'>
                  <InfoOutlined />
                  <span className='payment-method__bank-transfer__notice__text'>
                    You have to email us your receipt for confirmation at
                    tech@topship.africa.
                  </span>
                </div>
              )}
              {pageLoading && (
                <CustomSpinner
                  text={
                    walletPaymentLoading || additionalPaymentLoading
                      ? 'Processing Payment'
                      : 'Initializing Payment'
                  }
                  textSize='1.5rem'
                  textColor='var(--color-primary)'
                  size='4rem'
                  thickness='.6rem'
                  background='#e6e3df'
                  spinColor='var(--color-blue-dark)'
                />
              )}
            </div>
          </div>
          <div className='new-shop-n-ship__prompt__footer'>
            <div className='new-shop-n-ship__prompt__footer__wrap'>
              <div className='new-shop-n-ship__prompt__footer__actions'>
                <button onClick={onClose}>Close</button>
                <button
                  disabled={
                    !isMultipleShipmentBankTransfer && !paymentMethod.name
                  }
                  onClick={handlePayment}
                >
                  {showBankInfo || isMultipleShipmentBankTransfer
                    ? 'Confirm Payment'
                    : `Pay ${toCurrency(amount)}`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`new-shop-n-ship__prompt__backdrop fade${
          openPrompt ? ' show' : ''
        }`}
      ></div>
    </>
  );
};

export default PaymentMethodPrompt;
