import { Alert } from '@mui/material';
import { InfoYellowIcon } from './asset-contants';

export const PackageNameCustomLabelMessage = ({
  tagName,
  country,
  isChinaImport,
  isExpress
}) => (
  <Alert
    severity={'info'}
    sx={{
      marginTop: '10px',
      fontSize: '1.2rem',
      fontFamily: 'General Sans',
      backgroundColor: 'rgba(239, 165, 22, .1)',
      border: '1px solid #EFA516',
      borderRadius: '5px',
      color: '#0F1414',
      '& .MuiAlert-icon': {
        fontSize: '1.6rem',
        marginRight: '5px',
        marginTop: '2px',
        color: '#EFA516'
      }
    }}
    icon={
      <img
        src={InfoYellowIcon}
        alt='info'
        style={{
          width: '24px'
        }}
      />
    }
  >
    {isChinaImport ? (
      <>
        The sender’s name should bear ‘ [identifier tag] [first name] [last
        name]
        <b> e.g 邓涛topship - Jane Doe.</b> Please add the identifier tag
        <b>-邓涛topship</b> to your name, so your package can be easily
        identified when it lands at the China warehouse.
      </>
    ) : isExpress ? (
      <>
        On Express Shop & Ship service, we require that you enter <b>ONLY</b>{' '}
        this tag
        <b> “Topship Africa / LOS 80205”</b> as your name when shipping to our
        hub. Your package will be identified via the tracking number you provide
      </>
    ) : (
      <>
        Please include <b>‘{tagName}’</b> for <b>{country}</b> shipping, in the
        sender’s name when sending to any of the addresses above e.g{' '}
        <b>Tayo Aluko - {tagName}</b>{' '}
      </>
    )}
  </Alert>
);
