import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { shipmentPaymentState } from '../../recoil/atoms';
import { Link } from 'react-router-dom';
import paymentSuccessIcon from '../../assets/svg/payment-successful.svg';
import SubmitButton from '../submit-button';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useUpdateShipmentMutation } from '../../operations/mutations';
import { formatUUID } from '../../pages/Dashboard/helper-functions';

const PaymentSuccessfull = ({
  heading,
  subHeading,
  path,
  buttonText,
  successImage
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [, setRequestPage] = useRecoilState(shipmentPaymentState);
  const [updateShipment, updateShipmentResult] = useUpdateShipmentMutation(
    data => {
      if (data) {
        setIsCompleted(true);
      }
    }
  );
  const location = useLocation();

  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const shipmentID = useMemo(() => {
    const id = formatUUID(query.get('shipmentID'));

    setIsCompleted(!id);

    return id;
  }, [query]);

  useEffect(() => {
    if (shipmentID && !isCompleted) {
      updateShipment(shipmentID, { shipmentStatus: 'PaymentPending' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentID]);

  return (
    <div className='payment-successful'>
      <Helmet>
        <meta name='description' content='Topship Africa Shipment Payment' />
        <title>Topship Africa Shipment Payment Successful</title>
        <link
          rel='canonical'
          href='https://topship.africa/shipment-payment-successful'
        />
      </Helmet>
      <img src={successImage || paymentSuccessIcon} alt='success' />

      <h1>{heading || 'Payment Successful'}</h1>
      <p className='mt-1'>
        {subHeading ||
          'A receipt has been sent to your email containing the order information for your shipment.'}
      </p>

      <div className='mt-6 mb-3'>
        <Link to={path || '/'}>
          <SubmitButton
            text={buttonText || 'Go Home'}
            className='mt-3'
            disabled={!isCompleted}
            loading={updateShipmentResult.loading}
            onClick={() => setRequestPage(0)}
          />
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccessfull;
