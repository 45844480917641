import {
  DashboardIcon,
  DashboardIconActive,
  ShipmentIcon,
  ShipmentIconActive,
  AddressIcon,
  AddressIconActive,
  WalletIcon,
  WalletIconActive,
  FaqIcon,
  FaqIconActive,
  InviteIcon,
  InviteIconActive,
  ProfileIcon,
  ProfileIconActive,
  SettingsIcon,
  SettingsIconActive,
  ResourceIcon,
  ResourceActiveIcon
} from './asset-contants';
export const sidebarLinks = [
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    activeIcon: DashboardIconActive,
    link: '/',
    label: 'Dashboard Guide'
  },
  {
    name: 'My Shipments',
    icon: ShipmentIcon,
    activeIcon: ShipmentIconActive,
    link: '/past-shipments',
    label: 'Past Shipments Guide'
  },
  {
    name: 'My Shop & Ship orders',
    icon: ShipmentIcon,
    activeIcon: ShipmentIconActive,
    link: '/past-shopnships',
    label: 'Past Shopnship Guide'
  },
  {
    name: 'My China imports',
    icon: ShipmentIcon,
    activeIcon: ShipmentIconActive,
    link: '/past-china-imports',
    label: 'Past China Imports Guide'
  },
  {
    name: 'Wallet',
    icon: WalletIcon,
    activeIcon: WalletIconActive,
    link: '/wallet',
    label: 'Wallet Guide'
  },
  {
    name: 'Pending Charges',
    icon: WalletIcon,
    activeIcon: WalletIconActive,
    link: '/pending-charges'
  },
  // {
  //   name: 'VIB',
  //   icon: VibIcon,
  //   activeIcon: VibIconActive,
  //   link: '/vib',
  //   label: 'Vib Guide'
  // },
  {
    name: 'My Addresses',
    icon: AddressIcon,
    activeIcon: AddressIconActive,
    link: '/addresses',
    label: 'Addresses Guide'
  },
  {
    name: 'Settings',
    icon: SettingsIcon,
    activeIcon: SettingsIconActive,
    link: '/settings',
    label: 'Settings Guide'
  },
  {
    name: 'Invite & Earn',
    icon: InviteIcon,
    activeIcon: InviteIconActive,
    link: '/referral',
    label: 'Invite Guide'
  },
  {
    name: 'Guides & Resources',
    icon: ResourceIcon,
    activeIcon: ResourceActiveIcon,
    link: '/guides-and-resources',
    label: 'Resources Guide'
  },
  {
    name: 'FAQs',
    icon: FaqIcon,
    activeIcon: FaqIconActive,
    link: '/faq',
    label: 'Faq Guide'
  },
  {
    name: 'Profile',
    icon: ProfileIcon,
    activeIcon: ProfileIconActive,
    link: '/profile',
    label: 'Profile Guide'
  }
];
