export function GetShopnshipHubAddress(country, pricingTier, places) {
  switch (country) {
    case 'US':
      return pricingTier === 'Express'
        ? '4297 Express Lane, Sarasota, FL 34249 Sarasota Florida 34249-2602 TEL: 941 326 2501'
        : '172-30 Baisley Blvd Jamaica, NY 11434';

    case 'UK':
      return pricingTier === 'Express'
        ? 'Aramex UK SNS Unit 9 Skyport Drive West Drayton Harmondsworth UB7 0LB TEL: 01753-210399'
        : '2b Herbert Road off Plumstead Common East London UK SE18 3SH';

    case 'CA':
      return pricingTier === 'Express'
        ? '5810 Ambler Drive, Unit 14 Mississauga Ontario L4W 4J5 TEL: 001 (905) 238-0440'
        : 'Unit 162, 1885-1895 Clements Road Pickering Ontario CANADA L1W 3R8';

    default:
      return '';
  }
}
