import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import Input from '../../../../../components/new-dashboard/custom-input';
import SubmitButton from '../../../../../components/submit-button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  filteredShopNShipLinksState,
  shopNShipDetailsState,
  userState
} from '../../../../../recoil/atoms';

import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import { useHistory } from 'react-router-dom';
import { shopNShipLinks } from '../../../../../constants/shopnship-links';
import { shopnshipSelectedCountryState } from '../../../../../recoil/atoms';
import { GetShopnshipHubAddress } from '../../../../../constants/shopnship-hub-address';

const ShopNShipPersonalInformation = () => {
  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );
  const [user] = useRecoilState(userState);

  const history = useHistory();
  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);
  const currentIndex = filteredLinks.findIndex(
    link => link.link === history.location.pathname
  );
  const places = useMemo(() => {
    return {
      UK: `UK Hub: ${GetShopnshipHubAddress(
        'UK',
        shopNshipDetails?.pricingTier
      )}`,
      US: `USA Hub: ${GetShopnshipHubAddress(
        'US',
        shopNshipDetails?.pricingTier
      )}`,
      CANADA: `CANADA Hub: ${GetShopnshipHubAddress(
        'CA',
        shopNshipDetails?.pricingTier
      )}`,
      CHINA: 'CHINA Hub:'
    };
  }, [shopNshipDetails?.pricingTier]);
  const selectedCountry = useRecoilValue(shopnshipSelectedCountryState);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('please enter an email address'),
    phoneNumber: yup.string().required('Please enter a phone number'),
    shippingFrom: yup.string().required('Where are you shipping from?')
  });
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      shippingFrom: ''
    },

    onSubmit: values => {
      setSaveShopNshipDetails({ ...shopNshipDetails, ...values });
      history.push(filteredLinks[currentIndex + 1].link);
    }
  });
  // useEffect(() => {
  //   if (dirty) {
  //     history.push(filteredLinks[currentIndex + 1].link);
  //   }
  //   // eslint-disable-next-line
  // }, [shopNshipDetails]);

  useEffect(() => {
    const keys = Object.keys(shopNshipDetails);
    const _values = Object.values(shopNshipDetails);
    const formKeys = Object.keys(values);
    _values.forEach((value, index) => {
      const formKey = formKeys.find(key => keys[index] === key);
      if (value && shopNshipDetails[keys[index]] === value && formKey) {
        setFieldValue(keys[index], value);
      }
    });
    setFieldValue('email', user?.email);
    setFieldValue('phoneNumber', user?.phoneNumber);
    if (selectedCountry?.value === 'US') {
      setFieldValue('shippingFrom', places.US);
    } else if (selectedCountry?.value === 'CA') {
      setFieldValue('shippingFrom', places.CANADA);
    } else if (selectedCountry?.value === 'UK') {
      setFieldValue('shippingFrom', places.UK);
    } else {
      setFieldValue('shippingFrom', places.CHINA);
    }

    // eslint-disable-next-line
  }, [selectedCountry, shopNshipDetails]);

  return (
    <NewShopNShipStepsLayout mobileTitle='Personal Information'>
      <div className='new-shop-n-ship__modal__body'>
        <div className='new-shop-n-ship__modal__body__wrap'>
          <div className='new-shop-n-ship__modal__body__content'>
            <form
              onSubmit={handleSubmit}
              className='new-shop-n-ship__modal__body__form'
            >
              <Grid container rowSpacing='16px' columnSpacing='10px'>
                <Input
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email}
                  onChange={handleChange}
                  customLabel='Email address'
                  name='email'
                  id='email'
                  type='email'
                  containerClassName='new-shop-n-ship__modal__body__form__item__input'
                  placeholder='garrick@topship.com'
                  lg={6}
                  md={12}
                />
                <Input
                  id='phoneNumber'
                  name='phoneNumber'
                  error={Boolean(errors.phoneNumber) && touched.phoneNumber}
                  helperText={
                    errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : ''
                  }
                  onChange={(value, code) => {
                    setFieldValue('phoneNumber', value);
                  }}
                  value={values.phoneNumber}
                  type='tel'
                  placeholder='+234 801 234 5678'
                  containerClassName='new-shop-n-ship__modal__body__form__item__input'
                  customLabel='Phone Number'
                  lg={6}
                  md={12}
                />
                {selectedCountry?.label === 'China' ? null : (
                  <div id='input-control'>
                    <label className='mb-0' htmlFor='shippingFrom'>
                      <span>Where are you shipping from? </span>
                    </label>
                    <div className='radio-group'>
                      <div
                        className={`radio-group-option${
                          values.shippingFrom === places.US ? ' active' : ''
                        }`}
                      >
                        <input
                          type='radio'
                          id='shippingFrom'
                          name='shippingFrom'
                          onChange={handleChange}
                          checked={values.shippingFrom === places.US}
                          value={values.shippingFrom}
                        />
                        <span
                          onClick={() => {
                            setFieldValue('shippingFrom', places.US);
                          }}
                        >
                          <strong>USA Hub:</strong>
                          {GetShopnshipHubAddress(
                            'US',
                            shopNshipDetails?.pricingTier
                          )}
                        </span>
                      </div>
                      <div
                        className={`radio-group-option${
                          values.shippingFrom === places.UK ? ' active' : ''
                        }`}
                      >
                        <input
                          type='radio'
                          id='shippingFrom'
                          name='shippingFrom'
                          onChange={handleChange}
                          checked={values.shippingFrom === places.UK}
                          value={values.shippingFrom}
                        />
                        <span
                          onClick={() =>
                            setFieldValue('shippingFrom', places.UK)
                          }
                        >
                          <strong>UK Hub:</strong>
                          {GetShopnshipHubAddress(
                            'UK',
                            shopNshipDetails?.pricingTier
                          )}
                        </span>
                      </div>
                      <div
                        className={`radio-group-option${
                          values.shippingFrom === places.CANADA ? ' active' : ''
                        }`}
                      >
                        <input
                          type='radio'
                          id='shippingFrom'
                          name='shippingFrom'
                          onChange={handleChange}
                          checked={values.shippingFrom === places.CANADA}
                          value={values.shippingFrom}
                        />
                        <span
                          onClick={() => {
                            setFieldValue('shippingFrom', places.CANADA);
                          }}
                        >
                          <strong>CANADA Hub:</strong>
                          {GetShopnshipHubAddress(
                            'CA',
                            shopNshipDetails?.pricingTier
                          )}
                        </span>
                      </div>
                    </div>
                    <small>{touched.shippingFrom && errors.shippingFrom}</small>
                  </div>
                )}
              </Grid>
              <div className='new-shop-n-ship__modal__body__form__item__buttons'>
                <div
                  className='new-shop-n-ship__modal__body__form__item__button'
                  onClick={() =>
                    history.push(shopNShipLinks[currentIndex - 1].link)
                  }
                >
                  Go Back
                </div>
                <SubmitButton text='Continue' />
              </div>
            </form>
          </div>
        </div>
      </div>
    </NewShopNShipStepsLayout>
  );
};

export default ShopNShipPersonalInformation;
