import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  chinaImportState,
  filteredShopNShipLinksState,
  saveShopNShipRateState,
  selectedRateState,
  shopNShipDetailsState,
  shopnshipSelectedCountryState,
  shopnshipSelectedProductState
} from '../../../../../recoil/atoms';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Grid, Skeleton } from '@mui/material';
import NewShopNShipStepsLayout from '../../../../../components/Layouts/shop-n-ship-steps-layout';
import SubmitButton from '../../../../../components/submit-button';
import AddressGuidePrompt from '../../../../../components/modals/address-guide-prompt';
import { useGetShopNShipRateQuery } from '../../../../../operations/queries';

import BusyOverlay from '../../../../../components/busy-overlay';
import emptyStateIcon from '../../../../../assets/svg/empty.svg';
import { DeliveryOptions2 } from '../../../../../components/shipping-options';
import { GetShopnshipHubAddress } from '../../../../../constants/shopnship-hub-address';
import Input from '../../../../../components/new-dashboard/custom-input';
import { getCountryTags } from '../../../../../constants/shopnship-constants';
import { PackageNameCustomLabelMessage } from '../../../../../constants/shopnship-custom-label-message';

const shippingFromOptions = [
  {
    value: 'UK',
    label: 'United Kingdom'
  },
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'CA',
    label: 'Canada'
  }
];

const ShopnshipShippingInformation = () => {
  const [shopNshipDetails, setSaveShopNshipDetails] = useRecoilState(
    shopNShipDetailsState
  );
  const history = useHistory();
  const [isChinaImport] = useRecoilState(chinaImportState);

  const [showAddressGuide, setShowAddressGuide] = useState(false);
  const [selectedRate, setSelectedRate] = useRecoilState(selectedRateState);

  const handleRateSelection = rate => {
    setSelectedRate(rate);
    setFieldValue('pricingTier', rate?.pricingTier);
    if (rate?.pricingTier === 'Express') {
      setFieldValue('packageName', expressLabel);
    } else {
      setFieldValue('packageName', '');
    }
  };

  const [filteredLinks] = useRecoilState(filteredShopNShipLinksState);
  const expressLabel = 'TOPSHIP AFRICA/LOS 80205';
  const isExpressOption = selectedRate?.pricingTier === 'Express';
  const countryTags = getCountryTags();
  const tagName = useMemo(() => {
    return countryTags[shopNshipDetails?.shippingFrom?.split(' ')[0]] || '';
  }, [countryTags, shopNshipDetails?.shippingFrom]);

  const packageRegex = new RegExp(
    `${
      shopNshipDetails?.shippingFrom?.includes('UK')
        ? countryTags?.UK
        : shopNshipDetails?.shippingFrom?.includes('US')
        ? countryTags?.US
        : shopNshipDetails?.shippingFrom?.includes('CA')
        ? countryTags?.CA
        : countryTags?.CHINA
    }`,
    'g'
  );
  const expressValidation = yup.string().required('Package Name is required');

  const standardValidation = yup
    .string()
    .required('Package Name is required')
    .matches(packageRegex, {
      message: `Please include "- ${
        countryTags[shopNshipDetails?.shippingFrom?.split(' ')[0]]
      }" `
    });
  const validationSchema = yup.object().shape({
    packageName: isExpressOption ? expressValidation : standardValidation,
    pricingTier: yup.string().required('Please select an option')
  });

  const currentIndex = filteredLinks.findIndex(
    link => link.link === history.location.pathname
  );

  const [getShopNshipRates, { loading }] = useGetShopNShipRateQuery(() => {});

  const [shopNShipRates] = useRecoilState(saveShopNShipRateState);

  const {
    values,
    touched,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      pricingTier: '',
      packageName: ''
    },

    onSubmit: values => {
      setSaveShopNshipDetails({ ...shopNshipDetails, ...values });
      history.push(filteredLinks[currentIndex + 1].link);
    }
  });

  const [selectedCountry, setSelectedCountry] = useRecoilState(
    shopnshipSelectedCountryState
  );
  const countryName = shopNshipDetails?.shippingFrom?.split(' ')[0];
  const places = useMemo(() => {
    return {
      UK: `UK Hub: ${GetShopnshipHubAddress(
        'UK',
        shopNshipDetails?.pricingTier
      )}`,
      US: `US Hub: ${GetShopnshipHubAddress(
        'US',
        shopNshipDetails?.pricingTier
      )}`,
      CANADA: `CANADA Hub: ${GetShopnshipHubAddress(
        'CA',
        shopNshipDetails?.pricingTier
      )}`,
      CHINA: 'CHINA Hub:'
    };
  }, [shopNshipDetails?.pricingTier]);

  const [selectedProduct, setSelectedProduct] = useRecoilState(
    shopnshipSelectedProductState
  );

  useEffect(() => {
    if (selectedProduct) {
      setSelectedProduct(selectedProduct);
    }
    if (shopNshipDetails) {
      setSelectedCountry(selectedCountry);
    } else {
      setSelectedCountry(shippingFromOptions[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedProduct, shopNshipDetails]);

  useEffect(() => {
    if (selectedCountry?.value === 'US') {
      setFieldValue('shippingFrom', places.US);
    } else if (selectedCountry?.value === 'CA') {
      setFieldValue('shippingFrom', places.CANADA);
    } else if (selectedCountry?.value === 'UK') {
      setFieldValue('shippingFrom', places.UK);
    } else {
      setFieldValue('shippingFrom', places.CHINA);
    }

    if (shopNshipDetails) {
      setFieldValue('pricingTier', shopNshipDetails.pricingTier);
      setFieldValue('packageName', shopNshipDetails.packageName);
      setSelectedRate({ pricingTier: shopNshipDetails.pricingTier });
    }

    // eslint-disable-next-line
  }, [selectedCountry?.value, shopNshipDetails]);

  useEffect(() => {
    if (selectedProduct?.label) {
      const itemCategory = selectedProduct?.label?.includes('Not an electric')
        ? 'Others'
        : 'Gadgets';
      const params = {
        shippingFrom: selectedCountry?.value,
        items: { category: itemCategory, weight: 1 }
      };
      getShopNshipRates(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct?.label, selectedCountry?.value]);

  return (
    <>
      <NewShopNShipStepsLayout mobileTitle='Package Information'>
        <div className='new-shop-n-ship__modal__body'>
          <div className='new-shop-n-ship__modal__body__wrap'>
            <div className='new-shop-n-ship__modal__body__content'>
              <form
                onSubmit={handleSubmit}
                className='delivery-options new-shop-n-ship__modal__body__content__welcome-guide__shipping-guide'
              >
                <div className='mt-4'>
                  <DeliveryOptions2
                    loading={loading}
                    shopNShipRates={shopNShipRates}
                    selectedRate={selectedRate}
                    handleRateSelection={handleRateSelection}
                    lightColors={[]}
                    emptyStateIcon={emptyStateIcon}
                    selectedCountry={selectedCountry}
                    isPerKg={true}
                  />
                </div>
                <Grid container rowSpacing='16px' columnSpacing='16px'>
                  <Input
                    name='packageName'
                    value={values.packageName}
                    error={touched.packageName && Boolean(errors.packageName)}
                    helperText={touched.packageName && errors.packageName}
                    customLabelMessage={
                      <PackageNameCustomLabelMessage
                        tagName={tagName}
                        country={countryName}
                        isChinaImport={isChinaImport}
                        isExpress={isExpressOption}
                      />
                    }
                    onChange={handleChange}
                    customLabel='Name on package'
                    type='text'
                    placeholder={
                      isChinaImport
                        ? '邓涛topship - Jane Doe'
                        : isExpressOption
                        ? expressLabel
                        : `e.g: Garrick Braide - ${
                            countryTags[
                              shopNshipDetails?.shippingFrom?.split(' ')[0]
                            ] || ''
                          }`
                    }
                    containerClassName='new-shop-n-ship__modal__body__form__item__input'
                    fullWidth
                    disabled={isExpressOption}
                  />
                </Grid>

                <div>
                  <div className=''>
                    <label htmlFor='UK'>Delivery address</label>
                    {values.packageName &&
                    values.packageName.includes(
                      isExpressOption ? expressLabel : tagName
                    ) ? (
                      <div>
                        <div className='address-info-wrap mb-2'>
                          {selectedCountry?.value && (
                            <>
                              <div className='address-info'>
                                <strong>{selectedCountry.value} Hub: </strong>
                                {GetShopnshipHubAddress(
                                  selectedCountry.value,
                                  selectedRate.pricingTier
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <small>
                          Please input the above address as your shipping
                          address, your items get shipped there.
                        </small>
                      </div>
                    ) : (
                      [...Array(1).keys()].map(key => (
                        <div key={key} className={`delivery-options__item`}>
                          <div className='delivery-options__item__wrap discount'>
                            <div className='delivery-options__item__content w-100'>
                              <div className='delivery-options__item__name w-100'>
                                <Skeleton className='w-100' />
                              </div>
                              <div className='delivery-options__item__address w-100'>
                                <Skeleton className='w-100' />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                <div className='delivery-options__footer'>
                  <div
                    onClick={() =>
                      !shopNshipDetails?.id &&
                      history.push(filteredLinks[currentIndex - 1].link)
                    }
                    className='delivery-options__footer__action'
                  >
                    Go Back
                  </div>
                  <SubmitButton text='Continue' />
                </div>
              </form>
            </div>
          </div>
        </div>
        <BusyOverlay loading={loading} speed='1.7s' />
      </NewShopNShipStepsLayout>
      <AddressGuidePrompt
        show={showAddressGuide}
        setShow={setShowAddressGuide}
      />
    </>
  );
};

export default ShopnshipShippingInformation;
